@import "./_03_core.scss";
@import "./_05_views.scss";
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/** your custom css code **/
$bg-color: hsl(256, 33, 10);
$dot-color: hsl(256, 33, 70);

//Heading-color
$pink-color: #ff1395;
$button-pink-color: #ff1395;
$heading-pink-color: #ff1395;


// Dimensions
$dot-size: 1px;
$dot-space: 22px;
body {
  font-family: "Quicksand", sans-serif !important;
  overflow-x: hidden;
  position: relative;
}

.dropdwn-head {
  display: flex;
  width: 100%;
}

.handimage {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  bottom: -92px;
}

.dropdwn-head .react-select {
  width: 51%;
  margin: 0 0 0 10px;
  display: inline-block;
}

/*newletter*/
.commonsubhead {
  font-size: 14px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}

.commonboldtext {
  font-size: 30px;
  color: $heading-pink-color;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}

.footerbg {
  background-image: url("../../img/footerbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.commoninput {
  border: 0 !important;
  border-bottom: 1px solid $pink-color !important;
  border-radius: 0 !important;
}

.commoninput::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #b9baba;
}

.commoninput:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #b9baba;
}

.commoninput::placeholder {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #b9baba;
}

.commonbtn {
  border-radius: 25px !important;
  background-color: $button-pink-color !important;
  padding: 10px 30px;
  border-color: $pink-color !important;
}

.commonborderbtn {
  border-radius: 25px !important;
  border: 1px solid $pink-color;
  padding: 10px 60px;
}

.commonmsg {
  font-size: 20px;
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}

.maintitle {
  font-size: 30px;
  color: #1676b2;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  margin: 0 auto 50px;
}

.joinsmarttitle {
  font-size: 50px;
  color: $heading-pink-color;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  width: 85%;
  text-align: center;
  margin: 0 auto;
}

.joinsmarttext {
  font-size: 20px;
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  text-align: center;
  width: 47%;
  margin: 20px auto 50px;
}

.circle {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 160px;
  margin: 0 auto;
}

.circle-bgcolorPink {
  background-color: #ffe5f3;

  span {
    font-size: 50px;
    color: $pink-color;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
  }

  p {
    font-size: 20px;
    color: #e51587;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    padding: 0 32px;
  }
}

.circle-bgcolorBlue {
  background-color: #dbf3ff;

  span {
    font-size: 50px;
    color: #0daefe;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
  }

  p {
    font-size: 20px;
    color: #2996cd;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    padding: 0 32px;
  }
}

.brainimg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// .aboutbg {
// }

.commonsectionpadding {
  padding: 70px 0;
  position: relative;
}

.aboutdetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0 0;

  span {
    font-size: 24px;
    color: #000;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    text-align: center;
    line-height: 28px;
    margin: 20px 0 0 0;
  }

  p {
    font-size: 16px;
    color: #000;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    text-align: center;
  }
}

.bornsmartbg {
  background-image: url("../../img/bornsmartbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.parentbg {
  background-image: url("../../img/parentbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.reallovebg {
  background-image: url("../../img/reallovebg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bornsmartdetails {
  h5 {
    font-size: 20px;
    color: #1676b2;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    text-align: left;
  }

  span {
    font-size: 50px;
    font-weight: 600;
    text-align: left;
    color: #1676b2;
    display: inline-flex;
    font-family: "Quicksand", sans-serif;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 59px;
  }

  p {
    font-size: 18px;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin: 25px 0 0 0;
  }
}

.bornsmartgif {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.parent-title {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  color: $heading-pink-color;
  width: 50%;
  margin: 0 auto;
  line-height: 1.5;
}

.parent-details {
  width: 65%;
  margin: 20px auto;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  color: #000;
}

.paymentbluebg {
  border-radius: 8px;
  border: solid 2px #daeffa;
  background-color: #f3fbff;
  position: relative;
  height: 600px;
}

.paymentpinkbg {
  border-radius: 8px;
  border: solid 2px $pink-color;
  background-color: #fff5fb;
  position: relative;
  height: 600px;
}

.pricinglist {
  margin: 0 auto;
  padding: 0;
  width: 88%;

  li {
    font-size: 16px;
    padding: 10px 0;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    color: #000;
    list-style: none;
    display: inline-flex;
    align-items: flex-start;
  }
}

.smartprimarytext {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #000;
}

.smartboldtext {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: normal;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  width: 61%;
  margin: 15px auto;
}

.colorblue {
  color: #0daefe;
}

.colorpink {
  color: $pink-color;
}

.pricingImg {
  position: relative;
  text-align: center;
  top: -18px;
  width: 150px;
  height: 150px;
  margin: 0 auto;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
}

.textwidth {
  width: 66% !important;
}

.monthselect .react-select__control {
  background: transparent !important;
  border: 0;
}

.monthselect .css-1uccc91-singleValue {
  color: #0fc0d0;
  top: 41%;
}

.parentcontent p {
  font-size: 18px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  line-height: 1.33;
  padding: 15px 0 0 0;
  letter-spacing: -1px;
}

.parentvideo {
  width: 100% !important;
}

.parentcontent {
  margin: 30px 34px;
}

.knowtitle {
  font-size: 40px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  letter-spacing: -1px;
  text-align: center;
  color: $heading-pink-color;
}

.knowtext {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 0 15px;
}

.visualtext {
  font-size: 34px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  line-height: 1.15;
  letter-spacing: -1px;
  text-align: left;
  color: #0daefe;
}

.visuallabel {
  border-radius: 11px;
  background-color: #f3f3f3;
  font-size: 12px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  padding: 3px 10px;
  text-align: center;
  color: $pink-color;
}

.kidsdevcontent::-webkit-scrollbar {
  width: 5px;
}

/* Track */

/* Handle */
.kidsdevcontent::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #e9e9e9;
}

.kidsdevcontent::-webkit-scrollbar-thumb:window-inactive {
  background: #e9e9e9;
}

.kidsdevcontent {
  margin: 20px 0 0 0;
  overflow-y: scroll;
  height: 267px;

  ul {
    margin: 0;
    padding: 0 0 0 22px;
    list-style: disc;
    list-style-position: outside;

    li {
      // list-style: none;
      font-weight: bold;

      span {
        font-size: 20px;
        font-weight: bold;
        font-family: "Quicksand", sans-serif;
        line-height: 1.5;
        letter-spacing: -1px;
        text-align: left;
        color: #000;
      }

      p {
        font-size: 14px;
        line-height: 1.57;
        color: #000;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
      }
    }
  }
}

.newslettersection {
  padding: 50px 0 0 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.headernav {
  position: fixed !important;
  width: 100%;
  background-color: transparent !important;
  z-index: 99999 !important;
  top: 0;
}

.me-auto.navbar-nav a:hover {
  text-decoration: none !important;
  border-bottom: 2px solid #0eadfe;
}

.me-auto.navbar-nav a {
  font-size: 14px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  border-bottom: 2px solid #ff1496;
  color: #000;
}

.parentloginbtn {
  border-radius: 22px;
  font-family: "Quicksand", sans-serif;
  background-image: linear-gradient(to top, $pink-color, #960efe);
  font-size: 14px;
  color: #fff !important;
  padding: 5px 20px;
  font-weight: 600;
}

.me-auto.navbar-nav a:nth-child(4) {
  margin-right: 0 !important;
  border-right: 1px solid #c17ca2;
  padding-right: 10px;
}

.language-button {
  background: transparent !important;
  border: 0 !important;
  color: $pink-color !important;
}

.language-button:focus {
  box-shadow: none !important;
}

.header-color {
  background-color: #fff !important;
  position: relative;
  z-index: 1;
  box-shadow: -3px 6px 10px -3px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: -3px 6px 10px -3px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: -3px 6px 10px -3px rgba(0, 0, 0, 0.16);
}

.footernav a {
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  margin-right: 18px;
  color: #000;
}

.footer-contact {
  text-align: right;
}

.footerinfolinks ul {
  margin: 0;
  padding: 0;
  text-align: right;

  li {
    list-style: none;
    display: inline-flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    a {
      font-size: 13px;
      font-family: "Quicksand", sans-serif;
      font-weight: 600;

      color: #000;
    }
  }
}

.footerinfolinks ul li:nth-child(1) {
  padding-right: 30px;
}

.copyright {
  font-size: 12px;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;

  color: #517181;
}

.footerlinks ul {
  text-align: right;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: inline-flex;

    a {
      font-size: 12px;
      font-family: "Quicksand", sans-serif;
      font-weight: 500;
      margin-left: 15px;
      color: #517181;
    }
  }
}

.footercontactbtn {
  border-radius: 25px;
  border: solid 1px $pink-color;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $pink-color;
  font-family: "Quicksand", sans-serif;
  padding: 6px 25px;
}

.sociallinks {
  text-align: center;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: inline-flex;
      padding: 0 10px;
    }
  }
}

.bornsmartbgcommon {
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  margin: 0 auto;
}

.bornsmartbgdesign1 {
  background-color: #aa77c3;
  width: 278px;
  height: 278px;
  color: #f0d0ff;
  box-shadow: 1.2px 3.8px 0 0 rgba(177, 89, 133, 0.15);
}

.bornsmartbgdesign1.dotpattern {
  background: linear-gradient(
                  90deg,
                  #aa77c3 ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#aa77c3 ($dot-space - $dot-size), transparent 1%) center,
  $dot-color;
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign2 {
  background-color: #227ab2;
  width: 324px;
  height: 324px;
  color: #a3dbff;
  box-shadow: 1.2px 3.8px 0 0 rgba(49, 169, 186, 0.15);
}

.bornsmartbgdesign2.dotpattern {
  background: linear-gradient(
                  90deg,
                  #227ab2 ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#227ab2 ($dot-space - $dot-size), transparent 1%) center,
  $dot-color;
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign3 {
  background-color: #c94227;
  width: 255px;
  height: 255px;
  color: #ffd7cf;
  box-shadow: 1.2px 3.8px 0 0 rgba(152, 112, 54, 0.15);
}

.bornsmartbgdesign3.dotpattern {
  background: linear-gradient(
                  90deg,
                  #c94227 ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#c94227 ($dot-space - $dot-size), transparent 1%) center,
  rgba(152, 112, 54, 0.15);
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign4 {
  background-color: #d77130;
  width: 320px;
  height: 320px;
  color: #ffdfcb;
  box-shadow: 1.2px 3.8px 0 0 rgba(152, 112, 54, 0.15);
}

.bornsmartbgdesign4.dotpattern {
  background: linear-gradient(
                  90deg,
                  #d77130 ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#d77130 ($dot-space - $dot-size), transparent 1%) center,
  rgba(152, 112, 54, 0.15);
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign5 {
  background-color: #04b0fe;
  width: 290px;
  height: 290px;
  color: #ffdfcb;
  box-shadow: 1.2px 3.8px 0 0 rgba(19, 92, 129, 0.15);
}

.bornsmartbgdesign5.dotpattern {
  background: linear-gradient(
                  90deg,
                  #04b0fe ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#04b0fe ($dot-space - $dot-size), transparent 1%) center,
  rgba(19, 92, 129, 0.15);
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign6 {
  background-color: #48b767;
  width: 242px;
  height: 242px;
  color: #d1ffde;
  box-shadow: 1.2px 3.8px 0 0 rgba(89, 169, 84, 0.15);
}

.bornsmartbgdesign6.dotpattern {
  background: linear-gradient(
                  90deg,
                  #48b767 ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#48b767 ($dot-space - $dot-size), transparent 1%) center,
  rgba(89, 169, 84, 0.15);
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign7 {
  box-shadow: 1.2px 3.8px 0 0 rgba(49, 169, 186, 0.15);
  background-color: #edab2b;
  width: 252px;
  height: 252px;
  color: #fffce0;
}

.bornsmartbgdesign7.dotpattern {
  background: linear-gradient(
                  90deg,
                  #edab2b ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#edab2b ($dot-space - $dot-size), transparent 1%) center,
  rgba(49, 169, 186, 0.15);
  background-size: $dot-space $dot-space;
}

.bornsmartbgdesign8 {
  box-shadow: 1.2px 3.8px 0 0 rgba(183, 54, 38, 0.15);
  background-color: #fc5528;
  width: 179px;
  height: 179px;
  color: #ffd4c8;
}

.bornsmartbgdesign8.dotpattern {
  background: linear-gradient(
                  90deg,
                  #fc5528 ($dot-space - $dot-size),
                  transparent 1%
  ) center,
  linear-gradient(#fc5528 ($dot-space - $dot-size), transparent 1%) center,
  rgba(183, 54, 38, 0.15);
  background-size: $dot-space $dot-space;
}

.bornsmarttext {
  font-size: 28px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  line-height: 1.27;
  font-weight: 600;
}

.membershipbtn {
  position: absolute;
  bottom: -16px;
  left: 18%;
  margin: 0 auto;
  width: 66%;
  font-size: 17px;
  font-weight: bold !important;
  font-family: "Quicksand", sans-serif;
  color: #fff !important;
}

.customrangeslider {
  margin: 100px 0 0 0;
}

.commonblueborderbtn {
  border-radius: 25px !important;
  border: solid 1px #0eaefe !important;
  background-color: #fff !important;
  color: #0daefe !important;
}

.customrangeslider .rangeslider-horizontal {
  height: 2px;
  border-radius: 10px;
}

.customrangeslider .rangeslider-horizontal .rangeslider__fill {
  background-color: #0daefe;
}

.customrangeslider .rangeslider .rangeslider__handle {
  width: 22px;
  height: 22px;
  background-color: #0daefe;
  box-shadow: 0 9px 8px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}

.customrangeslider .rangeslider-horizontal .rangeslider__handle:after {
  background-color: transparent;
  box-shadow: none;
}

.customrangeslider .rangeslider-horizontal .rangeslider__handle-tooltip {
  border-radius: 30px;
  background-color: $pink-color;
  font-size: 16px;
  width: 100px;
  height: 50px;
}

.customrangeslider .rangeslider__labels .rangeslider__label-item {
  font-size: 12px;
  font-weight: 600;
  color: $pink-color;
  font-family: "Quicksand", sans-serif;
}

.newsletter-div .form-control {
  margin-bottom: 20px;
  margin-top: 30px;
  padding: 10px 0;
}

.newsletter-div button {
  padding: 8px 45px 8px;
  border-radius: 25px;
  background-color: $pink-color;
  font-size: 16px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.newsletter-div button:hover {
  color: #fff;
  background-image: linear-gradient(to top, $pink-color, #960efe);
}

.ul-expert-advice li {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.ul-expert-advice li img {
  margin-right: 10px;
}

.ul-expert-advice ul {
  padding-left: 25px;
  margin: 20px 0;
  list-style: none;
}

.view-all-btn a {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $pink-color;
  border-bottom: 1px solid $pink-color;
  font-family: "Quicksand", sans-serif;
}

.view-all-btn a:hover {
  text-decoration: none;
  color: $pink-color;
  border-bottom: 1px solid $pink-color;
}

.video-btn2 .commonbtn {
  border-radius: 25px !important;
  background-color: $pink-color !important;
  padding: 10px 30px;
  border-color: $pink-color !important;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-stretch: normal;
  width: 86%;
  display: inline-block;
}

.video-btn1 {
  margin-bottom: 20px;
}

.video-btn1 .commonborderbtn {
  border-radius: 25px !important;
  border: 1px solid $pink-color;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  text-align: center;
  color: $pink-color;
  font-family: "Quicksand", sans-serif;
  width: 86%;
  display: inline-block;
}

.shape1 {
  position: absolute;
  bottom: 8%;
  z-index: -1;
  left: 8%;
  animation: rotate3d 4s linear infinite;
  opacity: 0.4;
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape2 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 15%;
}

.shapeyellowstar {
  position: absolute;

  top: 25%;
  left: 15%;
}

.shapepinkstar {
  position: absolute;

  top: 32%;
  right: 15%;
}

.shape2new {
  position: absolute;
  z-index: -1;
  top: 50%;
  right: 20%;
}

.shape5 {
  position: absolute;
  right: 9%;
  bottom: 7%;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.freebgshape {
  position: absolute;
  right: 22%;
  top: 7%;

  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.checkmark {
  display: inline-block;
  padding-top: 8px;
  padding-right: 10px;

  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid #5ccd5d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.crossicon {
  width: 15px;
  height: 15px;
  opacity: 0.3;
  padding: 7px 16px 0 0;
}

.crossicon:hover {
  opacity: 1;
}

.crossicon:before,
.crossicon:after {
  position: absolute;

  content: " ";
  height: 10px;
  width: 3px;
  background-color: #333;
}

.crossicon:before {
  transform: rotate(45deg);
}

.crossicon:after {
  transform: rotate(-45deg);
}

.homepagebanner .carousel-caption {
  left: 112px;
  top: 20%;
  color: #000;
}

.slidercontent {
  background: #e6ffef;
  box-shadow: 0 28px 50px 0 rgba(0, 0, 0, 0.06);
  border-radius: 300px;
  width: 450px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  position: relative;

  h3 {
    font-size: 46px;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 8px;
    color: #16586e;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    font-family: "Montserrat", sans-serif;
  }
}

.bannerbtn .commonborderbtn {
  border-radius: 25px !important;
  border: 1px solid $pink-color;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  text-align: center;
  color: $pink-color;
  font-family: "Quicksand", sans-serif;
  width: 58%;
  display: inline-block;
  background-color: #fff;
  margin-top: 5px;
}

.bannerbtn .commonbtn {
  border-radius: 25px !important;
  background-color: $pink-color !important;
  padding: 10px 0;
  border-color: $pink-color !important;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-stretch: normal;
  width: 58%;
  display: inline-block;
  margin-bottom: 5px;
}

.bannerbtn {
  position: absolute;
  bottom: 0;
}

.react-select__menu.css-2613qy-menu {
  font-size: 11px !important;
}

.css-g1d714-ValueContainer {
  padding: 0 !important;
}

.css-1uccc91-singleValue {
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: initial !important;
}

.css-yk16xz-control {
  box-shadow: none !important;
  min-height: auto !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  min-height: auto !important;
}

.css-b8ldur-Input {
  margin: 0;
}

.css-tlfecz-indicatorContainer {
  padding: 0 !important;
}

.exprience-btn {
  margin-top: 20px;
}

.exprience-btn a {
  padding: 8px 30px 8px 30px;
  border-radius: 25px;
  background-color: $pink-color;
}

.shapegreen {
  position: absolute;
  left: 0;
  top: -9%;

  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shapepink {
  position: absolute;
  right: 0;
  bottom: -46px;

  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.startmembership {
  color: #fff;
  background-image: linear-gradient(to top, #b6ffce, #ffe50e), linear-gradient(to bottom, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Quicksand", sans-serif;
  display: flex;
  font-weight: 700;
  flex-direction: column;
  padding: 0 8px;
  text-transform: uppercase;
  // margin-bottom: 15px;
  margin-bottom: -24px;

  .fontsmall {
    font-size: 18px;
  }

  .fontbig {
    font-size: 32px;
  }

  .fontmedium {
    font-size: 22px;
  }
}

.font22 {
  font-size: 22px !important;
  padding: 0 18px;
}

.joinow a {
  color: #fff;
  font-size: 28px;
}

.starshape {
  position: absolute;
  top: 10%;

  left: 8%;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
}

.triangleshape {
  position: absolute;
  bottom: 10%;

  right: 8%;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
}

.sunshape {
  position: absolute;

  top: 45%;
  right: 5%;
}

.tooltips {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltips-position {
  position: absolute;
  top: 11%;
  left: 53%;
}

.tooltips-position-1 {
  position: absolute;
  top: 26%;
  left: 57%;
}

.tooltips-position-2 {
  position: absolute;
  top: 39%;
  left: 57%;
}

.tooltips-position-3 {
  position: absolute;
  top: 50%;
  left: 57%;
}

.tooltips-position-4 {
  position: absolute;
  top: 77%;
  left: 81%;
}

.tooltips-position-5 {
  position: absolute;
  top: 28%;
  left: 29%;
}

.tooltips-position-6 {
  position: absolute;
  left: 24%;
  top: 44%;
}

.tooltips-position-7 {
  position: absolute;
  top: 68%;
  left: 19%;
}

.tooltips .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 190px;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: #0daefe;
  color: #fff;
  text-align: center;
  padding: 8px 0 4px 0;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -11px;
  left: 176%;
}

.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  margin-right: -1px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #0daefe transparent transparent;
}

.pinkcircle {
  width: 8px;
  height: 8px;
  background-color: $pink-color;
  border-radius: 50px;
}

.bluecircle {
  width: 14px;
  height: 14px;
  border-radius: 50px;
  padding: 2px;
  border: solid 1px #0daefe;
  background-color: #fff;
}

.loginleftimg img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 170px;
}

.loginmargin {
  margin: 110px 0;
}

.logindetails h1 {
  font-size: 62px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  line-height: 1.06;
  text-align: center;
  color: #000;
  padding: 0 55px;
}

.formdiv {
  width: 70%;
  margin: 0 auto;
}

.loginbtn .commonbtn {
  border-radius: 25px !important;
  background-color: $pink-color !important;
  padding: 10px 30px;
  border-color: $pink-color !important;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-stretch: normal;
  width: 100%;
  display: inline-block;
}

.registerbtn {
  border-radius: 25px;
  background-color: #ffebf6;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  color: $pink-color;
  display: block;
  text-align: center;
  padding: 10px 30px;
}

.joinow i {
  margin-left: 4px;
  padding: 0 0 0 0;
}

.joinow a:hover {
  color: #ffe50e;
  text-decoration: none;
}

.homepagebanner .carousel-control-next-icon {
  background-image: url("../../img/rightarrow.png");
  width: 17px;
  height: 31px;
}

.homepagebanner .carousel-control-prev-icon {
  background-image: url("../../img/rightarrow.png");
  width: 17px;
  height: 31px;
  transform: rotate(180deg);
}

.homepagebanner .carousel-control-prev {
  left: 0;
}

.homepagebanner .carousel-control-next {
  right: 0;
}

.formdiv input {
  border-bottom: 1px solid #d1d2d3 !important;
  border-left: 0 !important;
  background: transparent !important;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  position: relative;
  padding-left: 0;
}

.formdiv textarea {
  border-bottom: 1px solid #d1d2d3 !important;
  border-left: 0 !important;
  background: transparent !important;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  position: relative;
  padding-left: 0;
}

.formdiv input ::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #444;
}

.formdiv input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #444;
}

.formdiv input ::placeholder {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #444;
}

.formdiv label {
  /* font-size: 12px; */
  letter-spacing: -1px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.forgot {
  font-size: 11px;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  text-align: right;
  color: #000;
  display: block;
  margin: 5px 0 20px 0;
  text-decoration: underline;
}

.eyeIcon {
  position: absolute;
  right: 7px;
  bottom: 3px;
}

.formdiv .form-group {
  position: relative;
  text-align: left;
}

.registerbtndiv p {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000;
  font-family: "Quicksand", sans-serif;
  margin: 0;
}

.registerbtndiv span {
  font-size: 12px;
  display: block;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
  background: linear-gradient(to right, $pink-color 0%, #0eadfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.registerbtndiv {
  width: 100%;
  margin: 42px 0 0 0;
}

.logincopyright {
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #517181;
}

.subsmonth {
  display: inline-flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  width: 100%;

  li {
    list-style: none;
  }
}

.child-section-bg {
  border-radius: 8px;
  background-color: #eef9ff;
  padding: 27px 20px;
  margin-bottom: 30px;
}

.reghead {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #0fadfe;
  font-family: "Quicksand", sans-serif;
  margin: 0;
}

.subs-month {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.reg-price {
  border-top: 1px solid #e5d7df;
  width: 37%;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  color: #b17b99;
}

.formdiv .custom-control-label {
  font-size: 14px;
  font-weight: 600;
  color: #b17b99;
}

.add-child-btn {
  background: transparent !important;
  font-family: "Quicksand", sans-serif;
  font-size: 14px !important;
  font-weight: bold !important;
  color: $pink-color !important;
  border: transparent !important;
  letter-spacing: -1px;
}

.select-box-number .custom-react-select .react-select__control {
  border: 1px solid #d1d2d3 !important;
  padding: 2px 6px;
  font-size: 18px;
  font-weight: 600;
}

.subs-month .custom-control-label::before {
  border: solid 1px #dcb4ca;
  background-color: transparent;
  top: 1.1rem;
}

.subs-month .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $pink-color;
  border: solid 1px $pink-color;
}

.subs-month .custom-control-label::after {
  top: 1.1rem;
}

.reg-right {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin-top: 150px;
  margin-right: -57px;
}

.totalamount {
  display: flex;
  border-top: 2px solid #0fadfe;
  border-bottom: 2px solid #0fadfe;
  margin: 20px 0;
  padding: 10px 0;
  justify-content: space-between;
}

.totalpricetitle {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #000;
  font-family: "Quicksand", sans-serif;
}

.totalpricedetail {
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: right;
  color: #000;
}

.childinfo {
  border-radius: 10px;
  background-color: #ffffe3;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #0c2f40;
  font-family: "Quicksand", sans-serif;
  padding: 10px 20px;
  display: flex;
}

.childinfo p {
  padding: 0 0 0 24px;
  margin: 0;
}

.getmembershipmsg {
  margin: 20px 0 37px 58px;
}

.getmembershipmsg p {
  background: linear-gradient(to right, $pink-color 0%, #0eadfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin: 0;
}

.getmembershipmsg span {
  color: #495860;
  font-size: 12px;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  margin: 0;
}

.getmembershipinfo ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.getmembershipinfo ul li p {
  color: #c0287c;
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  padding: 0 0 0 20px;
}

.childinfo img {
  width: 38px;
}

.loginbtn img {
  width: 30px;
  margin-left: 16px;
}

.registerbtn img {
  margin-left: 10px;
}

.registerbtn:hover {
  color: $pink-color;
  text-decoration: none;
}

.regstarshape {
  position: absolute;
  left: -56px;
  top: 7%;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
}

.regstarshape img {
  width: 97px;
}

.substhankmsg {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -1px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  color: #000;
}

.thankyouemaildetails {
  font-family: "Quicksand", sans-serif;

  span {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #666;
  }

  p {
    font-weight: 600;
    color: #000;
  }
}

.thankyoudetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.thankyoudetails h1 {
  font-size: 62px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  line-height: 1.06;
  text-align: center;
  color: #000;
  padding: 0 55px;
}

.thankyouemail {
  margin: 30px 0 20px 0;
}

.form-control.input-padding {
  padding-left: 31px;
}

.usericon {
  position: absolute;
  top: 38px;
}

.bgRibbontop {
  position: absolute;
  top: -107px;
  left: 0;
  z-index: -1;
}

.bgRibbonbtm {
  position: absolute;
  bottom: -95px;
  right: 28px;

  z-index: -1;
  transform: rotate(45deg);
}

.bghexagonbtm {
  position: absolute;
  bottom: -31px;
  left: -14px;
  transform: rotate(76deg);
}

.bghexagonbtm img {
  width: 69px;
}

.handprint {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -100px;
  z-index: -1;
  width: 100%;
}

.purplebg {
  background-color: #8274e8;
  clip-path: ellipse(71% 81% at 50% 0);
  background-position: left bottom 75%, right bottom 75% !important;
}

.pinkbg {
  background-color: #e86ebc;
  clip-path: ellipse(71% 81% at 50% 0);
  background-position: left bottom 75%, right bottom 75% !important;
}

.innerheader {
  text-align: center;
  padding: 20px 0 70px 0;
  background-image: url("../../img/Pattern.png");
  font-family: "Quicksand", sans-serif;

  h1 {
    font-size: 80px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: center;
    color: #fff;
    margin: 0 0 20px 0;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    width: 29%;
    margin: 0px auto;
  }
}

.parentingmargin {
  padding: 91px 0 0 0;
}

.parent-opt {
  text-align: center;
  font-family: "Quicksand", sans-serif;

  img {
    border-radius: 150px;
  }

  h5 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: center;
    color: #000;
    margin: 30px 0 20px 0;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    width: 86%;
    margin: 0 auto 20px;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
}

.parent-newsletter {
  margin: 100px 0 50px 0;

  .commonboldtext {
    font-size: 55px;
    letter-spacing: -1px;
  }

  .newsletter-div {
    width: 80%;
    margin: 0 auto;
  }
}

.parent-opt .commonbtn {
  color: #fff;
  padding: 11px 47px;
  font-weight: 700;
}

.parent-opt .commonbtn:hover {
  color: #fff;
  background-image: linear-gradient(to top, $pink-color, #960efe);
}

.banner-btm-img {
  text-align: center;
  position: relative;
  top: -107px;
  width: 354px;
  height: 271px;
  margin: 0 auto;
}

.banner-btm-img img {
  border-radius: 71px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul.baby-months li {
  display: inline-flex;
  width: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

ul.baby-months {
  margin: 40px 0 0 0;
  padding: 0;
}

.baby-month-list .custom-control-label {
  text-align: center;
  color: #70797e;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  cursor: pointer;
  padding: 0 11px;
}

.baby-month-list .custom-control-input {
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
}

.baby-month-list .custom-control-label::before {
  visibility: hidden;
}

.baby-month-list .custom-control-label::after {
  visibility: hidden;
}

.baby-month-list .custom-control {
  padding-left: 0;
}

.baby-month-list .custom-control-input + label {
  border-radius: 35px;
  background-color: #fff;
  margin-right: 10px;
  cursor: pointer;
  height: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 35px;
}

.baby-month-list .custom-control-input:checked + label {
  background-color: $pink-color;
  color: #fff;
}

.baby-month-list img {
  position: absolute;
  top: 18px;
  z-index: 1;
  left: -9px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.baby-month-list {
  position: relative;
}

.parenting-detail {
  font-family: "Quicksand", sans-serif;

  h3 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: left;
    color: #000;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    color: #000;
  }

  .detail-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    text-align: left;
    color: #3d3d3d;
    font-family: "Montserrat", sans-serif;
  }

  .parenting-quest {
    font-size: 34px;
    font-weight: 700;
    letter-spacing: -1px;
    color: #000;
    margin: 30px 0 20px 0;
  }

  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    text-align: left;
    color: #3d3d3d;
    font-family: "Montserrat", sans-serif;
  }

  ::marker {
    color: #0eaefe;
  }
}

.sidemenu {
  border-right: 1px solid #8c8c8c;

  ul {
    margin: 0;
    padding: 0 46px 0 0;

    li {
      font-size: 18px;
      font-weight: 500;
      color: #3e3e3e;
      font-family: "Quicksand", sans-serif;
      list-style: none;
      padding: 0 0 20px 12px;
      cursor: pointer;
    }

    .activelink {
      border-radius: 10px;
      background-color: $pink-color;
      font-weight: 600;
      color: #fff;
      padding: 13px 15px;
      margin-bottom: 15px;
    }
  }
}

.custom-breadcrumb ol {
  padding-left: 10px;
}

.custom-breadcrumb ol li {
  font-size: 12px;
  font-family: "Quicksand", sans-serif;
  color: #7c7c7c;
  font-weight: 500;
}

.custom-breadcrumb ol li .MuiTypography-colorTextPrimary {
  font-size: 12px;
  font-family: "Quicksand", sans-serif;
  color: #000;
}

.detail-text-list {
  padding: 0 0 0 21px;
}

.handCenterTop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerTopAlign {
  display: flex;
}

.height54 {
  height: 54px !important;
}

.bluebg {
  background-color: #16a9f6;
  clip-path: ellipse(71% 81% at 50% 0);
  background-position: left bottom 75%, right bottom 75% !important;
}

.aboutImg img {
  border-radius: 100px;
}

.aboutcontent {
  font-family: "Quicksand", sans-serif;

  h5 {
    // font-size: 50px;
    font-size: 33px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: center;
    color: $heading-pink-color;
    margin: 50px 0;
  }

  h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    // margin: 0 auto 20px;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #000;
  }

  ul {
    margin: 0 auto;
    padding: 0;

    li {
      font-size: 22px;
      font-weight: 700;
      list-style: none;
      padding: 20px;
      display: flex;
      align-items: start;

      img {
        margin-right: 20px;
        width: 58px;
      }
    }
  }
}

.vision-list {
  li {
    background-image: url("../../img/reglist_icon.png");
    background-repeat: no-repeat;
    background-position: 0 28px;
    list-style: none;

    p {
      font-size: 22px;
      font-weight: 700;
      text-align: left;
      margin: 0;
      padding: 0 24px 0 27px;
    }
  }
}

.detail-msg {
  font-family: "Quicksand", sans-serif;
  text-align: center;

  p {
    width: 69%;
    font-size: 50px;
    margin: 0 auto;
    line-height: 1.16;
    color: #0daefe;
    letter-spacing: -1px;
    font-weight: 600;
  }
}

.child-right {
  position: absolute;
  right: 87px;
  top: -61px;
}

.child-left {
  position: absolute;
  left: 69px;
  bottom: -103px;
}

.moonshape {
  position: absolute;
  right: 0;
  top: auto;
  bottom: 19%;
}

.hexagonshape {
  position: absolute;
  top: 10%;
  left: 0;
}
.commontriangleshape {
  position: absolute;
  // right: 100px;
  right: 40px;
}

.commonstarshape {
  position: absolute;
  // left: 100px;
  left: 40px;
}

.quotes-left {
  position: absolute;
  left: 0;

  img {
    width: 250px;
  }
}

.quotes-right {
  position: absolute;
  right: 0;

  img {
    width: 250px;
    transform: rotate(180deg);
  }
}

.expert-data {
  font-family: "Quicksand", sans-serif;
  color: #000;

  .expertname {
    font-size: 40px;
    font-weight: 700;
  }

  .expertdesignation {
    font-size: 16px;
    font-weight: 700;
  }

  .expertcountry {
    font-size: 14px;
    font-weight: 400;
  }

  .expertdetails {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.38;
  }
}

.expert-description {
  font-size: 16px;
  font-weight: 300;
  font-family: "Quicksand", sans-serif;
}

span.text-style-1 {
  display: block;
  margin: 20px 0;
}

.expert-photos img {
  border-radius: 7px;
}

h3.expertname img {
  width: 128px;
  display: block;
  margin-top: 20px;
  margin-bottom: 12px;
}

.contact-list ul li {
  border-radius: 10px;
  box-shadow: 0 0 16px 0 rgba(41, 157, 218, 0.2);
  background-color: #fff;
  display: inline-flex;
  width: 21%;
  margin: 0 30px 24px 0;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  height: 190px;

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #000;
  }
}

.mobileapp_born_smart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h3 {
    font-size: 50px;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    text-align: left;
    color: $heading-pink-color;
  }
}

.born-smart-style {
  margin: 30px 0;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      background-image: url("../../img/reglist_icon.png");
      background-repeat: no-repeat;
      background-size: contain;

      p {
        font-size: 18px;
        font-family: "Quicksand", sans-serif;
        font-weight: 600;
        text-align: left;
        color: #000;
        padding: 0 0 0 38px;
      }
    }
  }
}

.download-app {
  font-family: "Quicksand", sans-serif;

  span {
    font-size: 18px;
    font-weight: 600;
    color: #373737;
    display: block;
    padding: 0 0 8px 0;
  }

  .download-app-icons {
    display: flex;
  }
}

.quest-title {
  font-family: "Quicksand", sans-serif;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -3px;
  text-align: center;
  color: $heading-pink-color;
}

.contact-us-text {
  text-align: center;
}

.contact-us-text a {
  text-align: center;
  font-size: 14px;
}

.contact-us-text i {
  font-size: 14px;
  margin-right: 7px;
}

.footerbg {
  padding: 40px 0 20px 0;
}

.border-row {
  border-bottom: 1px solid #fbfeff;
  padding-bottom: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.born-smart-video iframe {
  border-radius: 8px;
}

//dashboard

.notification-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.search-and-tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.search-box input {
  padding: 7px 19px;
  border-radius: 19px;
  background-color: #ebf8ff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #a2b0bc;
}

.your-subscription-tag-full {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 12px;
  border-radius: 16px;
  background-color: #ffe8e8;
  margin-left: 11px;
}

.your-subscription-tag-full p {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #eb0c0c;
  margin-bottom: 0;
}

.your-subscription-tag-full a {
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #eb0c0c;
  margin-bottom: 0;
  margin-left: 4px;
}

.subscription-div ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.subscription-div ul li {
  font-size: 14px;
  font-weight: normal;
  color: #1e3a48;
  padding: 0 4px;
}

.subscription-div ul li:nth-child(1) {
  color: #1e3a48;
  font-weight: 500;
}

.subscription-div ul li:nth-child(2) {
  color: #c0d5e9;
}

.subscription-div ul li:last-child {
  color: #c0d5e9;
}

.notification-icon-box ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.notification-icon-box ul li a {
  color: #526474;
}

.notification-icon-box ul li {
  padding: 0 12px;
  font-size: 14px;
  position: relative;
}

.view-details-btn a {
  font-size: 12px;
  font-weight: 500;
}

.language-box button {
  font-size: 14px;
  font-weight: 600;
  color: #1e3a48 !important;
  padding: 0 18px;
}

.language-box .dropdown-toggle::after {
  border-top: 0.3em solid $pink-color;
}

.profile-box span {
  width: 34px;
  height: 34px;
  padding: 4px 7px;
  background-color: #f2e7ed;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}

.profile-box span img {
  width: 100%;
}

.profile-box-full {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
}

.profile-name {
  margin-left: 10px;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.profile-name p {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.profile-name a {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #526474;
}

.menu-topbar-full {
  padding: 5px;
  border-bottom: 1px solid #e8f2f6;
  background: #fff;
  height: 60px;
  box-shadow: 0 1px 15px #cccccc85;
}

.log-out-div {
  margin-top: -2px;
}

//side-menu
.side-menu-text {
  text-align: center;
  background-color: #0dadfe;
  // height: 100%;
  width: 120px;
  height: calc(100% - 60px);
  z-index: 3;
  position: fixed;
  transition: transform .3s;
  padding-bottom: 10px;
  left: 0;
  margin: 60px 0 0 0;
}

.side-bar-inn a {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  padding: 15px;
  width: 100%;
  height: 86px;
}

.side-bar-inn a img {
  margin-bottom: 10px;
}

.side-bar-inn a.active {
  background-color: #0077cb;
}

.side-bar-inn a:hover {
  color: #fff;
  height: 86px;
  text-decoration: none;
}

.side-bar-inn.side-bar-inn-help {
  margin-top: 30px;
}

.welcome-text h2 {
  font-size: 54px;
  font-weight: normal;
  text-align: center;
  color: #1e3a48;
}

.welcome-text h2 span {
  color: $pink-color;
}

.welcome-text p {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
  color: #1e3a48;
}

.dashboard-right-data {
  padding: 35px 0;
}

.video-box {
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #efefef;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 30px;
}

.video-box.video-box-active {
  box-shadow: 0 14px 13px 0 rgba(0, 0, 0, 0.1);
  border: solid 2px $pink-color;
}

.video-box h6 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #1e3a48;
  margin: 10px 0;
}

.video-box-activity-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.video-box .video-box-activity-icon img {
  width: auto;
}

.video-box-activity-icon p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #265984;
  margin-left: 10px;
}

.big-video-box div {
  width: 100% !important;
}

.big-video-box iframe {
  width: 100% !important;
}

.subscription-div li b {
  padding: 1px 10px 1px;
  border-radius: 10px;
  background-color: #d9ffe8;
  font-weight: 600;
  font-size: 10px;
  color: #287c45;
}

.bell-notification {
  width: 6px;
  height: 6px;
  background-color: #22c03c;
  position: absolute;
  top: 0;
  border-radius: 50%;
}

.your-subscription-tag-full p img {
  margin-top: -3px;
  margin-right: 4px;
}


.radio-btn-with-img [type="search"] {
  outline-offset: 0 !important;
  -webkit-appearance: none !important;
}

.radio-btn-with-img :focus-visible {
  outline: -webkit-focus-ring-color auto 0;
}


.radio-btn-with-img .input-hidden {
  position: absolute;
  left: -9999px;
}

.radio-btn-with-img input[type=radio]:checked + label > img {
  border: 2px solid #fff;
  box-shadow: 0 0 3px 3px #0eadfe;
}

/* Stuff after this is only to make things more pretty */
.radio-btn-with-img input[type=radio] + label > img {
  //border: 1px dashed #444;
  width: 64px;
  height: 64px;
  padding: 10px;
  background-color: $pink-color;
  transition: 500ms all;
  border-radius: 50%;
  margin: 3px;
  box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.16);
}

.radio-btn-with-img ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-btn-with-img label {
  display: inline-block;
  margin-bottom: 0.5rem;
  border: 2px solid $pink-color;
  border-radius: 50%;
  cursor: pointer;
}

//.radio-btn-with--img input[type=radio]:checked + label>img {
//  transform:
//          rotateZ(-10deg)
//          rotateX(10deg);
//}
.radio-btn-with-img ul li {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.baby-name-age {
  margin-left: 15px;
}

.baby-name-age p {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.baby-name-age b {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1e3a48;
}

.welcome-text {
  margin-bottom: 50px;
}

.select-box-full {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.select-box-number {
  width: 150px;
}

.select-box-text {
  margin-left: 20px;
}

.select-box-text h5 {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.select-box-text p {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.select-box-number .css-1gtu0rj-indicatorContainer {
  padding: 0;
  color: #ff1495;
}

.select-box-number .css-tlfecz-indicatorContainer {
  color: #ff1495;
}

.select-box-number .css-1okebmr-indicatorSeparator {
  margin-bottom: 0;
  margin-top: 0;
  width: 0;
}

.video-details-box {
  margin: 70px 0;
  border-top: 1px solid #0dadfe;
  border-bottom: 1px solid #0dadfe;
  padding-top: 50px;
  padding-bottom: 50px;
}

.video-card-box {
  margin: 50px 0;
}

.video-main-heading h3 {
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  color: #1e3a48;
}

.video-main-heading p {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #265984;
  margin-bottom: 0;
}

.video-main-heading p img {
  margin-right: 10px;
}

.video-main-heading {
  border-bottom: 1px solid #d8ebf5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.video-details h5 {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
  text-transform: uppercase;
}

.video-details p {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #1e3a48;
}

.select-box-number .css-tlfecz-indicatorContainer:hover {
  color: #ff1495;
}

.happy-kids-box {
  text-align: center;
}

.happy-kids-box h4 {
  font-size: 24px;
  font-weight: 500;
  color: $pink-color;
  margin-bottom: 40px;
}

.happy-kids-box h5 {
  font-size: 32px;
  font-weight: bold;
  color: #1e3a48;
}

.happy-kids-box p {
  font-size: 18px;
  font-weight: 500;
  color: #1e3a48;
  width: 36%;
  margin: 20px auto;
}

.happy-kids-box img {
  margin-top: 40px;
}

//Profile
.profile-heading {
  margin-top: 35px;
  margin-bottom: 50px;
}

.profile-heading h2 {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #1e3a48;
}

.parents-tab-left ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.parents-img-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding: 10px;
}

.parents-img-box.parents-img-box-active {
  padding: 10px;
  background-color: #f0f5fa;
  border-radius: 5px 0 0 5px;
}

.parents-img-circle {
  border-radius: 50%;
  border: 2px solid $pink-color;
}

.parents-img-box.parents-img-box-active .parents-img-circle span {
  width: 50px;
  height: 50px;
  padding: 10px 10px;
  background-color: $pink-color;
  display: inline-block;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #fff;
  overflow: hidden;
}

.parents-name h3 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.parents-name span {
  margin-left: 5px;
}

.parents-name {
  margin-left: 10px;
}

.parents-name p {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.parents-tab-left {
  border-right: 2px solid #f0f5fa;
  height: 100%;
}

.parents-tab-left li a {
  cursor: pointer;
}

.parents-img-circle.kids-img-circle span {
  width: 50px;
  height: 50px;
  padding: 10px 10px;
  background-color: #f2e7ed;
  display: inline-block;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #fff;
}

.parents-img-circle.kids-img-circle {
  border-radius: 50%;
  border: 2px solid #fff;
}

.profile-input-box label {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
}

.profile-input-box .form-control {
  width: 100%;
  height: auto;
  padding: 10px 0;
  border-radius: 0;
  border: 0 solid #ced4da;
  border-bottom-width: 1px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1e3a48;
}

.form-control:focus {
  box-shadow: none !important;
}

.profile-input-box .css-yk16xz-control {
  border-radius: 0;
  border: 0 solid #ced4da;
  border-bottom-width: 1px;
  padding: 4px 0;
  font-size: 14px;
}

.profile-input-box .css-b8ldur-Input {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.profile-input-box .css-1okebmr-indicatorSeparator {
  width: 0;
}

.profile-input-box .css-tlfecz-indicatorContainer {
  color: #ff1495;
}

.update-btn {
  margin: 30px auto;
  text-align: center;
}

.update-btn button {
  padding: 7px 20px;
  border-radius: 25px;
  background-color: $pink-color;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  border: none;
  margin: 0 auto;
  width: 220px;
}

.choose-password-heading h4 {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
}

.update-btn.update-no-border button {
  background-color: transparent;
  color: $pink-color;
  border: 1px solid $pink-color;
}

.choose-password-heading {
  margin-top: 30px;
}


.radio-gender-box {
  background: transparent;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  position: relative;
}

.radio-gender-box input {
  color: #000;
  box-shadow: none;
  text-shadow: none;
  padding: 6px 50px !important;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 100ms linear;
  border: 1px solid #0dadfe;
  font-weight: 600;
}

.radio-gender-box input:checked {
  color: #fff;
  padding: 6px 50px;
  background: #0dadfe;
}

.radio-gender-box input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

.radio-gender-box ul {
  padding: 0;
  list-style: none;
  margin: 0 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-gender-box ul li:nth-child(1) input {
  border-radius: 10px 0 0 10px;
}

.radio-gender-box ul li:nth-child(2) input {
  border-radius: 0 10px 10px 0;
}

.profile-input-box.child-profile label {
  text-align: center;
  width: 100%;
}

.profile-input-box.child-profile input {
  text-align: center;
}

.child-date-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
}

.child-date-box label {
  //border-bottom: 1px solid #ddd;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: auto;
  text-align: left !important;
  width: auto !important;
}

.child-date-box .form-group {
  margin-bottom: 0;
}

.child-date-box .form-group input {
  border-bottom: 0;
}


.parents-img-box.parents-img-box-active .parents-img-circle {
  border-radius: 50%;
  border: 2px solid $pink-color;
  width: 54px;
  height: 54px;
}


h1 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 20px;
}

h1 small {
  display: block;
  font-size: 15px;
  padding-top: 8px;
  color: gray;
}

.avatar-upload {
  position: relative;
  width: 120px;
  height: 120px;
  text-align: center;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;
}

.avatar-upload .avatar-edit {
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: -13px;
  right: 0;
  left: 0;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  margin-bottom: 0;
  border-radius: 100%;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  display: none;
}

.avatar-upload .avatar-preview {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.upload-child-img {
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  background: #cfefff url("../../img/bg-upload-child.png");
}

.avatar-upload p {
  font-weight: 600;
  font-stretch: normal;
  text-align: center;
  color: $pink-color;
  font-size: 14px;
}

.parents-img-circle.kids-img-circle img {
  width: 100%;
  height: 100%;
}

.avatar-preview.delete-box-upload img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.avatar-preview.delete-box-upload img.delete-icon {
  position: absolute;
  width: auto;
  height: auto;
  border-radius: inherit;
  bottom: -17px;
  left: 0;
  right: 0;
  cursor: pointer;
  margin: 0 auto;
}

.add-img-icon {
  cursor: pointer;
}

.img-size-text p {
  font-size: 14px;
  color: #0077cb;
  margin-bottom: 40px;
}

.profile-input-box .form-control:disabled, .profile-input-box .form-control[readonly] {
  background-color: transparent;
}

.subscription-months-box div div:last-child {
  padding: 0;
}

.mobile-number-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobile-number-box b {
  border-bottom: 1px solid #ced4da;
  padding-top: 9px;
  padding-bottom: 11px;
  padding-right: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1e3a48;
  display: flex;
  width: 55px;
}

.mobile-number-box b span {
  margin-left: 6px;
}

.customrangeslider .StepRangeSlider__track {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: #bbb;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
}

.customrangeslider .StepRangeSlider__thumb {
  border-radius: 20px;
  width: 22px;
  height: 22px;
  border: 1px solid #0daefe;
  background-color: #0daefe;
}

.customrangeslider .StepRangeSlider__thumb:active {
  background-color: #0daefe;
  box-shadow: 0 9px 8px 0 rgba(0, 0, 0, 0.12);
}

.customrangeslider .StepRangeSlider__tooltip {
  position: absolute;
  bottom: 51px;
  left: 50%;
  transform: translateX(-50%);
  background: $pink-color;
  padding: 11px 0;
  border-radius: 30px;
  color: #fff;
  width: 90px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px
}

.customrangeslider .StepRangeSlider__handle::before span {
  background-image: url("../../img/arrow-sider.png");
  position: relative;
  bottom: 0;
  width: 18px;
  height: 9px;
  z-index: 5;
}

.customrangeslider ul li {
  color: $pink-color;
  font-size: 14px;
  font-weight: bold;
}

.customrangeslider ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
}

.number12 {
  padding-left: 5px;
}

.password-eyes {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.password-eyes-inn {
  width: 100%;
}

.subscription-div li b.paintagsubscription {
  background-color: #fbf1d7;
  color: #ee854b;
}

//history


.history-border-box {
  padding: 20px;
  border-radius: 10px;
  border: solid 1px #c7d0d8;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.history-border-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.history-border-box-inn ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-border-box-inn ul li h5 {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
}

.history-border-box-inn ul li {
  margin-right: 40px;
}

.history-border-box-inn ul li:last-child {
  margin-right: 0;
}

.history-border-box-inn ul li p {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.view-history-btn button {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  padding: 8px 63px;
}

.view-history-btn button:hover {
  background-color: #f70a8c !important;
}

.plan-and-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.plan-and-name h5 {
  font-size: 28px;
  font-weight: normal;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 0;
}

.tag-box p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #127433;
  padding: 1px 14px 2px;
  border-radius: 10px;
  background-color: #d9ffe9;
  margin-left: 10px;
  display: inline-block;
}

.tag-box p span {
  margin-right: 4px;
}

.tag-box.details-history p {
  margin-left: 0;
}

.tag-box p.paidtag {
  background-color: #fbf1d7;
  color: #ee854b;
}

.history-full-box-with-icon {
  position: relative;
}

.history-bg-star {
  position: absolute;
  left: 60px;
  top: 20px;

}

.history-bg-moon {
  position: absolute;
  left: 60px;
  bottom: 20px;
}

.history-bg-hexagon {
  position: absolute;
  right: 60px;
  top: 20px;
}

.history-bg-moon img {
  width: 65px;
}

.history-bg-hexagon img {
  width: 65px;
}

.forgot-pass-main.history-full-box-with-icon .history-bg-triangle {
  position: absolute;
  right: 60px;
  bottom: 20px;
  top: auto;
}

.history-bg-star img, .history-bg-hexagon img {
  width: 65px;
  -webkit-animation-name: rotation;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotation;
  -moz-animation-duration: 10s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -o-animation-name: rotation;
  -o-animation-duration: 10s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  animation-name: rotation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.history-bg-triangle {
  position: absolute;
  right: 60px;
  top: 20px;
}

.history-bg-triangle img {
  width: 65px;
  -webkit-font-smoothing: antialiased;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-10px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-15px);
  }
}

main {
  height: 100%;
}

.video-box img {
  width: 100%;
}

//history details

.video-count-box ul {
  padding: 0;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-box.details-history {
  text-align: center;
}

.video-count-box ul li {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1e3a48;
  list-style: none;
  padding: 0 10px;
  border-right: 1px solid #c0d5e9;
}

.video-count-box ul li b {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #1e3a48;
  margin-left: 10px;
}

.video-count-box ul li:last-child {
  padding-right: 0;
  border-right: none;
}

.video-view-box {
  border-top: 1px solid #0dadfe;
  padding-top: 20px;
}

.video-img-with-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-img-with-text-inn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.video-img-with-text-inn img {
  width: 90px;
  height: 50px;
}

.video-img-with-text-inn h5 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #1e3a48;
  margin-bottom: 4px;
}

.video-img-with-text-inn h6 {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #a2b0bc;
  margin-bottom: 4px;
}

.video-name {
  margin-left: 20px;
}

.review-box span {
  font-size: 14px;
  font-weight: normal;
  text-align: right;
  color: #15a164;
}

.review-box span.remaining {
  color: #a2b0bc;
}

.review-box span img {
  margin-left: 10px;
}

.video-view-box ul {
  padding: 0;
  margin: 0 0 30px 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-view-box li button.active-btn {
  background-color: #0dadfe;
  color: #fff;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.video-view-box li button {
  padding: 4px 20px;
  border-radius: 21px;
  border: solid 1px #0dadfe;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #0dadfe;
}

.video-view-box li {
  padding: 0 7px;
}

.video-view-box li:last-child {
  padding-right: 0;
}


.back-btn a {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: $pink-color;
  text-decoration: none;
}

.back-btn a i {
  font-size: 17px;
  font-weight: 600;
  margin-right: 7px;
}

.back-btn a:hover {
  text-decoration: none;
  color: #0eadfe;
}

.profile-heading.view-history-heading {
  margin-bottom: 0;
  margin-top: 0;
}

.profile-heading.view-history-heading h2 {
  margin-bottom: 0;
}

.tag-box.details-history {
  margin-bottom: 20px;
}

.back-with-heading {
  margin-top: 40px;
}

.forgot-pass-main.history-full-box-with-icon {
  margin-top: 150px;
  margin-bottom: 50px;
}

.forgot-pass-main.history-full-box-with-icon.website-coming-soon {
  margin-top: 40px;
  margin-bottom: 0;
}


.forgot-your-password-box h5 {
  font-size: 62px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: 15px;
}

.forgot-pass-main.history-full-box-with-icon .loginbtn {
  margin-top: 40px;
}

.forgot-your-password-box p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #666;
}

.return-link {
  text-align: center;
  margin-top: 20px;
}

.return-link p {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}

.return-link p a {
  color: $pink-color !important;
}

.coming-content {
  text-align: center;
  height: 63px;
}

.coming-content span {
  font-family: Quicksand;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: $pink-color;
  line-height: 0.75;
  padding: 0 0 25px 0;
  text-transform: uppercase;
}

.video {
  margin: 0 auto;
  text-align: center;
}

.video img {
  text-align: center;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.G1 {
  transform: translate(20px, 85px) rotate(-80deg);
}

.e1 {
  transform: translate(15px, 25px) rotate(-20deg);
}

.e2 {
  transform: translate(12px, 16px) rotate(-20deg);
}

.k1 {
  transform: translate(10px, 6px) rotate(-16deg);
}

.s1 {
  transform: translate(11px, 1px) rotate(-10deg);
}

.f {
  transform: translate(11px, -1px) rotate(-8deg);
}

.o {
  transform: translate(11px, -2px) rotate(0deg);
}

.r {
  transform: translate(-1px, 8px) rotate(13deg);
}

.G2 {
  transform: translate(22px, 0px) rotate(9deg);
}

.e3 {
  transform: translate(21px, 6px) rotate(15deg);
}

.e4 {
  transform: translate(19px, 14px) rotate(20deg);
}

.k2 {
  transform: translate(18px, 26px) rotate(25deg);
}

.s2 {
  transform: translate(-20px, 80px) rotate(30deg);
}

span {
  display: inline-block;
}

/*harsha*/


.testimoal-full {
  padding: 50px;
  background-image: url("../../img/testimonial/rectangle-bg.png");
  width: 100%;
  height: auto;
  background-position: center;
  position: relative;
  margin-top: 100px;
  background-size: cover;
}

.testi-label {
  width: 100%;
  height: 100%;
  display: flex;
}

.testi-label span img {
  border-radius: 23px;
  background-color: #ffc600;
  padding: 15px 12px;
}

.testi-content {
  width: 100%;
  position: relative;
  margin: 0 0 0 10px;
}

.testi-data {
  padding: 0 80px 0 0px;
}

.testi-desc h4 {
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #000;
  line-height: 1.43;
}

.testi-content h6 {
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin: 0;
  color: #000;
}

.testi-content p {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #000;
  margin: 0 0 10px 0;
}

.testi-img img {
  border-radius: 50%;
}

.testimoal-full button.slick-arrow.slick-prev {
  top: 107%;
  left: 44%;
  color: $pink-color;
  background-image: url("../../img/testimonial/previous-arrow.png");
  background-repeat: no-repeat;
  width: 50px;
  height: 20px;
}

.testimoal-full button.slick-arrow.slick-next {
  bottom: 0;
  top: 107%;
  right: 44%;
  color: $pink-color;
  background-image: url("../../img/testimonial/next-arrow.png");
  background-repeat: no-repeat;
  width: 50px;
  height: 20px;
}

.testimoal-full button.slick-arrow.slick-prev::before {
  display: none;
}

.testimoal-full button.slick-arrow.slick-next::before {
  display: none;
}

h1.testi-numb {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: $pink-color;
}

.expert-sectionpadding {
  padding: 70px 0 180px;
  position: relative;
}

.slick-slider.h-slick-slider {
  margin: 120px 0 0 0;
  padding: 80px 0 0 0;
}

.h-slick-slider .slick-dots {
  top: -110px;
  bottom: auto;
}

.h-slick-slider .slick-dots li {
  width: 160px;
  height: 100px;
  margin: 0 0;
}

.h-slick-slider .slick-dots li.slick-active a img {
  border: solid 2px $pink-color;
  background-color: #fff5fc;
  border-radius: 100px;
  padding: 4px 4px;
}

.h-slick-slider .slick-dots li.slick-active a p {
  color: $pink-color;
}

.h-slick-slider .slick-dots li.slick-active a.expert-pagination img {
  filter: grayscale(0);
}

.h-slick-slider .slick-dots li a.expert-pagination img {
  width: 86px;
  border-radius: 50px;
  height: 86px;
  filter: grayscale(100%);
}

.expert-pagination p {
  font-size: 14px;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  color: #515151;
  margin: 10px 0 0 0px;
}

.h-slick-slider button.slick-arrow.slick-prev {
  bottom: 0;
  top: 120%;
  left: 44%;
}

.h-slick-slider button.slick-arrow.slick-next {
  bottom: 0;
  top: 120%;
  right: 44%;
}

.h-slick-slider button.slick-arrow.slick-prev {
  color: $pink-color;
  background-image: url('../../img/Arrow_L.svg');
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.h-slick-slider button.slick-arrow.slick-prev::before {
  display: none;
}

.h-slick-slider button.slick-arrow.slick-next {
  color: $pink-color;
  background-image: url('../../img/Arrow_R.svg');
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.h-slick-slider button.slick-arrow.slick-next::before {
  display: none;
}

.triangle-sect {
  position: relative;
  margin: 60px 0 0 0;
}

.million-content {
  position: absolute;
  width: 100%;
  top: 25px;
}

.million-content img {
  width: 40%;
  margin-top: 25px;
}

.million-content h2 {
  text-align: center;
  color: #0642b1;
  font-size: 45px;
  margin-bottom: 0;
  font-weight: 600;
}

.million-content h4 {
  font-size: 70px;
  font-weight: 600;
  text-align: center;
  color: #0642b1;
  font-family: "Quicksand", sans-serif;
  line-height: 0.69;
}

.million-content p {
  font-family: "Quicksand", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  color: #0642b1;
  margin: 20px 15%;
}

.million-content p {
  margin: 0;
  font-size: 30px;
}

.million-content1 p {
  margin: 0px 23%;
  padding-top: 15px;
}

.triangle {
  position: relative;
  background-color: #f8eb20;
  text-align: left;
}

.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}

.triangle,
.triangle:before,
.triangle:after {
  width: 450px;
  height: 450px;
  border-top-right-radius: 45%;
}

.triangle {
  transform: rotate(-60deg) skewX(-30deg) scale(1, .866);
}

.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}


.heart-sun-icon {
  position: relative;
  width: 100%;
}

.heart {
  position: absolute;
  left: 0;
  top: 250px;
}

.sun {
  position: absolute;
  right: 0;
  top: 200px;
}

.healthy-content p {
  font-family: "Quicksand", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;
  color: #000;
  padding: 25px 0;
}

.testi-2 p {
  font-family: "Quicksand", sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #0642b1;
  margin: 0px 25%;
  line-height: 1.4;
  padding: 50px 50px;
}

.expert-star-icons {
  width: 100%;
  position: relative;
}

// .expert-star-icons .slider-star {
//   position: absolute;
//   left: 120px;
//   top: 72%;
// }
.expert-star-icons .slider-star {
  position: absolute;
  left: 200px;
  top: 63%;
}

.expert-star-icons .slider-star-pink {
  position: absolute;
  right: 200px;
  top: 63%;
  left: auto;
}

.dashboard-right-data {
  padding: 100px 0 35px;
}

.girl-img {
  width: 60px;
  height: 60px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 auto;
}

.girl-img img {
  border-radius: 35px;
}

.testi-1 h3 {
  font-size: 20px;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
}

.testi-1 p {
  font-size: 16px;
  color: #000;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
  font-weight: normal;
}

.faq-tab-left {
  border-right: 2px solid #8c8c8c;
  height: 100%;
}

.faq-tab-left ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.faq-tab-left li a {
  cursor: pointer;
}

.faq-q-head h5 {
  font-family: Quicksand;
  font-size: 50px;
  font-weight: bold;
  text-align: left;
  color: #000;
}

.faq-card.card {
  border: none;
  border-bottom: 1px solid #e1eef1;
  border-radius: 0;
  margin: 10px 0 10px 0;
  padding: 10px 0;
}

.h-cardTitle {
  width: 100%;
  display: flex;
  align-items: center;
}

.h-cardTitle h5 {
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #000;
  cursor: pointer;
  margin-bottom: 0;
}

.h-cardTitle span img {
  margin: 0 20px 0 0;
}

.faq_cardBody {
  padding: 0px 0 0 58px;
}

.faq_cardBody p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #3d3d3d;
  margin-bottom: 5px;
}

.active-home-card h5 {
  color: $pink-color;
}

.faq_cardBody.card-body {
  padding: 0 0 0 56px;
}

.faq-img-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding: 10px;
  width: 285px;
}

.faq-img-box-active {
  padding: 10px;
  background-color: $pink-color;
  border-radius: 5px 5px 5px 5px;
}

.faq-img-box-active .faq-name h3 {
  color: #fff;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 500;
}

.faq-name h3 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 0;
}

.forgot-pass-main.history-full-box-with-icon.h-icon-padding {
  margin-top: 0;
  margin-bottom: 50px;
}

.privacy-content .contDetails strong {
  font-family: Quicksand;
  font-size: 34px;
  font-weight: bold;
  text-align: left;
  color: $heading-pink-color;
}

.privacy-content .contDetails p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  color: #3d3d3d;
  line-height: 1.75;
}

.privacy-content .contDetails ul {
  list-style: none;
  padding: 0 18px;
}

.privacy-content .contDetails ul li::before {
  content: "\2022";
  color: #0eaefe;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.privacy-content .contDetails ul li em {
  font-family: Quicksand;
  font-weight: 600;
  color: #000;
  font-style: inherit;
}

.privacy-content .contDetails ul li {
  text-align: justify;
  font-size: 16px;
  font-weight: 300;
  color: #3d3d3d;
}

.privacy-content .contDetails b {
  font-family: Quicksand;
  font-size: 34px;
  font-weight: bold;
  text-align: left;
  color: $heading-pink-color;
  line-height: 40px;
}

.h-handprint {
  bottom: -43px;
}

.commonstarshape img {
  animation-name: rotation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.commontriangleshape img {
  animation-name: rotation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.centerImgLeft img {
  animation-name: rotation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.centerImgRight img {
  animation-name: rotation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.home-body {
  position: relative;
}

.float-div {
  position: fixed;
  bottom: 100px;
  right: 0;
  z-index: 1030;
}

.float-div a {
  display: inline-block;
  cursor: pointer;
}

.float-div img {
  width: 100px;
}

.float-div button img {
  width: auto;
}

.float-div button {
  position: absolute;
  z-index: 999999;
  right: 0;
  bottom: 5px;
  background: #f413a1;
  border: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  left: 0;
  text-align: center;
  margin: 0 auto;
  padding: 0 0;
  line-height: 8px;
}

.export-box-slider {

  padding: 50px;
  background-image: url("../../img/parentbg.png");
  width: 100%;
  height: auto;
  background-position: center;
  background-size: 157% 100%;
  position: relative;
  //clip-path: polygon(50% 3%, 80% 12%, 100% 35%, 100% 70%, 80% 88%, 50% 96%, 20% 89%, 0% 70%, 0% 35%, 20% 15%);
  //border-radius: 730px / 106px;
  //-moz-border-radius: 730px / 106px;
  //-webkit-border-radius: 730px / 106px;
}

.slider-star {
  position: absolute;
  left: 200px;
  top: 120px;
}

.slider-star-pink {
  position: absolute;
  right: 200px;
  top: 120px;
  left: auto;
}

.export-box-slider h2 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: $pink-color;
  margin-bottom: 50px;
}

.export-box-slider p {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #000;
  width: 70%;
  margin: 0 auto 50px;
}

.export-box-slider h4 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.export-box-slider h4 img {
  display: block;
  width: 143px;
  margin: 20px auto 30px;
  text-align: center;
}

.export-box-slider h5 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.export-box-slider h6 {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #000;
}

.export-box-slider .slick-prev:before, .export-box-slider .slick-next:before {
  font-size: 40px;
  color: $pink-color;
}

//.export-box-slider .slick-next:before {
//  content: none;
//  background-image: url("../../img/rightarrow.png");
//}
//.export-box-slider .slick-next{
//  background-image: url("../../img/rightarrow.png");
//  background-color: $pink-color;
//  border-radius: 50%;
//}

.animatedbaby img {
  border-radius: 30px;
}

.offering-banner img {
  border-radius: 70px;
}

.offering-banner h6 {
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
  margin-top: 40px;
}

.offering-banner p {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}

.content-covers-full {
  padding: 50px;
  background-image: url("../../img/offering/rectangle-bg.png");
  width: 100%;
  height: auto;
  background-position: center;
  position: relative;
  margin-top: 100px;
  background-size: cover;
}

.content-covers-inn h5 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: $heading-pink-color;
  margin-bottom: 50px;
}

.content-covers-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.baby-box {
  width: 63px;
  height: 86px;
  padding: 23px 10px;
  border-radius: 35px;
  background-color: #ffe6fc;
  text-align: center;
}

.content-covers-box {
  padding: 25px 71px 25px 25px;
  border-radius: 11px;
  box-shadow: 0 19px 10px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
}


.baby-text h4 {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: $pink-color;
  margin-bottom: 5px;
}

.baby-text {
  margin-left: 20px;
}

.baby-text p {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #d467a3;
  margin-bottom: 0;
}

.content-covers-box-details h4 {
  font-size: 27px;
  font-weight: bold;
  text-align: left;
  color: #010101;
  margin-bottom: 0;
  padding-bottom: 11px;
}

.content-covers-img-box {
  margin-left: -70px;
  margin-top: 35px;
}

.content-covers-img-box iframe {
  border-radius: 20px;
  width: 85%;
  display: block;
  height: 300px;
}

.content-covers-box-details img {
  width: 130px;
}

.content-covers-box-details p {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
  margin-bottom: 0;
  margin-top: 15px;
  min-height: 135px;
}

.stage-line {
  position: absolute;
  top: 21px;
  left: 58px;
}

.stage-line span {
  width: 3px;
  height: 30px;
  background-color: #0eadfe;
}

.stage-line h3 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 7.2px;
  text-align: left;
  color: #0eadfe;
  margin-left: -7px;
  margin-bottom: 5px;
}

.content-covers-with-stage-line {
  position: relative;
  padding-top: 80px;
  padding-bottom: 70px;
}

.stage-line span.big-line {
  height: 80px;
}

.stage-line.big-stage-line {
  position: absolute;
  top: auto;
  bottom: -16px;
  left: 58px;
}

.content-covers-with-stage-line.ten-month-box .baby-text h4 {
  color: #ec910f;
}

.content-covers-with-stage-line.ten-month-box .baby-text p {
  color: #dea14b;
}

.content-covers-with-stage-line.ten-month-box .baby-box {
  background-color: #fff1df;
}

.content-covers-with-stage-line.ninety-one-month-box .baby-text h4 {
  color: #20b48c;
}

.content-covers-with-stage-line.ninety-one-month-box .baby-text p {
  color: #65bba4;
}

.content-covers-with-stage-line.ninety-one-month-box .baby-box {
  background-color: #cefff2;
}

.content-covers-with-stage-line.twenty-seven-month-box .baby-text h4 {
  color: #a11bbe;
}

.content-covers-with-stage-line.twenty-seven-month-box .baby-text p {
  color: #b36bc3;
}

.content-covers-with-stage-line.twenty-seven-month-box .baby-box {
  background-color: #f7e9ff;
}

//.content-covers-with-stage-line.ninety-one-month-box .content-covers-img-box {
//  margin-top: 21px;
//}

.smart-program-bg {
  padding: 50px 0;
  background-image: url("../../img/offering/smart-bg.png");
  background-size: 110% 100%;
  background-repeat: no-repeat;
}

.well-researched-box {
  background-color: #ffe6dd;
  padding: 20px;
  border-radius: 50%;
  width: 400px;
  transform: rotateZ(-4deg);
  margin-left: -80px;
  margin-bottom: 35px;
}

.well-researched-box h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #f61717;
}

.with-effective-box {
  background-color: #ceffdb;
  padding: 20px;
  width: 400px;
  transform: rotate(-2deg);
}

.with-effective-box h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #00731f;
}

.mentoring-box {
  border-radius: 70px;
  background-color: #faffbf;
  padding: 20px;
  width: 400px;
  transform: rotate(2deg);
}

.mentoring-box h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #bc840d;
}

.content-covers-inn {
  padding: 50px 0;
}

.free-membership-box p {
  margin: 0;
  font-size: 20px;
  line-height: 1.55;
  letter-spacing: -0.48px;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(to top, #b6ffce, #ffe50e), linear-gradient(to bottom, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.free-membership-box h3 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.72px;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(to top, #b6ffce, #ffe50e), linear-gradient(to bottom, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.free-membership-box {
  width: 320px;
  height: 320px;
  padding: 44px 47px 29px;
  box-shadow: 1.2px 3.8px 0 0 rgba(19, 92, 129, 0.15);
  background-color: #0daefe;
  border-radius: 50%;
  text-align: center;
  margin: 60px auto 50px;
}

.free-membership-box-full .wave-left {
  position: absolute;
  left: 50px;
  transform: translate(-50%);
  top: 50%;
  right: auto;
}

.free-membership-box-full .wave-left img {
  width: 100px;
}

.free-membership-box-full .wave-right {
  position: absolute;
  left: auto;
  top: 50%;
  right: -50px;
  transform: translate(-50%);
}

.free-membership-box-full .wave-right img {
  width: 100px;
}

.free-membership-box-full {
  position: relative;
}

.stars {
  background-image: url(../../img/offering/yellow-star.png), url(../../img/offering/red-star.png);
  background-position: left 75px top 0px, right 17px top 5px;
  background-repeat: no-repeat;
}

.star1 {
  position: absolute;
  right: -100px;
  bottom: 32%;
}

.star1 img {
  width: 50%;
}

.commonstarshape1 img {
  width: 50%;
}

.commonstarshape1 {
  position: absolute;
  top: 49%;
  left: -45px;
}

.dots img {
  width: 80%;
}

.dots {
  position: absolute;
  left: -6%;
  top: 3%;
}

.dots1 {
  position: absolute;
  right: 0%;
  top: 12%;
}

.dots2 {
  position: absolute;
  bottom: 5%;
  left: -5%;
}

.dots3 {
  position: absolute;
  bottom: 10%;
  right: -6%;
}

.star {
  position: absolute;
  top: 82%;
  left: -90px;
}

.red-star {
  position: absolute;
  right: -10%;
  bottom: 14%;
}

.rectangle {
  background-color: #a7ffbe;
  text-align: center;
  padding: 20px;
  border-radius: 25px;
  position: relative;
}

.bg-image img {
  width: 100%;
  height: 350px;
  border-radius: 25px;
  background-size: 100% 100%;
}

.bg-image iframe {
  border-radius: 25px;
  margin-top: 25px;
}

.bg-image p {
  font-family: "Quicksand", sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #0642b1;
  padding: 45px 70px;
}

.commonstarshape2 img {
  width: 80%;
}

.commonstarshape2 {
  position: absolute;
  top: 54%;
  left: 45px;
}

.star2 img {
  width: 80%;
}

.star2 {
  position: absolute;
  right: 10px;
  bottom: 39%;
}

.zip1 {
  position: absolute;
  left: 13%;
  bottom: 20%;
}

.zip2 {
  position: absolute;
  top: 33%;
  right: 13%;
}

.brain-dev-content h5 {
  color: $heading-pink-color;
  font-size: 40px;
  font-weight: bold;
}

.triangle-space {
  margin-bottom: 10em;
}

.brain-dev-content p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

.girl-chart {
  margin: 50px 0;
}

.girl {
  position: absolute;
  left: -50px;
  bottom: 112px;
}

.chart {
  margin-left: 0px;
}

.brain-content p {
  text-align: center;
  color: #595959;
  font-size: 16px;
  line-height: 1.5;
}

.main-box {
  width: 100%;
  margin-top: 5em;
  background: url(../../img/box.png);
}

.well-researched-box1 {
  background-color: #ffe6dd;
  padding: 30px;
  border-radius: 50%;
  width: 400px;
  transform: rotateZ(-4deg);
  margin-left: -80px;
  margin-bottom: 35px;
  margin-top: 25px;
}

.well-researched-box1 h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #f61717;
}

.with-effective-box1 {
  background-color: #ceffdb;
  padding: 29px;
  width: 450px;
  transform: rotate(-2deg);
}

.with-effective-box1 h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #00731f;
}

.mentoring-box1 {
  border-radius: 70px;
  background-color: #fae1ff;
  padding: 30px;
  width: 425px;
  transform: rotate(2deg);
}

.mentoring-box1 h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #941bac;
}

.well-researched-box2 {
  background-color: #d0f6ff;
  padding: 30px;
  border-radius: 50%;
  width: 400px;
  transform: rotateZ(4deg);
  margin-left: 31px;
  margin-bottom: 46px;
  margin-top: 50px;
}

.well-researched-box2 h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #0583c4;
}

.with-effective-box2 {
  background-color: #f8ffa5;
  padding: 30px;
  width: 425px;
  transform: rotate(-1deg);
  margin-top: 40px;
}

.with-effective-box2 h5 {
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  color: #93520c;
}

.stars1 {
  background-image: url(../../img/star2.png), url(../../img/star1.png), url(../../img/star3.png), url(../../img/star4.png);
  background-position: right 42px top 50px, left 89px top 33px, left 33px bottom 25px, right 82px bottom 60px;
  background-repeat: no-repeat;
}

.wave-left1 {
  position: absolute;
  top: 50%;
  left: -40px;
}

.wave-right1 {
  position: absolute;
  right: -40px;
  top: 50%;
}

.star-brain {
  position: absolute;
  top: 57%;
  left: 0px;
}

.red-star-brain {
  position: absolute;
  right: 0%;
  bottom: 40%;
}

.testi-2 img {
  width: 40%;
  margin-top: 70px;
}

.testi-2 {
  width: 100%;
  height: 600px;
  background: url(../../img/round.png) center no-repeat;
}

.learning-program h2 {
  text-align: center;
  color: #0eadfe;
  font-size: 38px;
  font-weight: bold;
  padding: 0 52px;
  position: relative;
}

.zip-left {
  position: absolute;
  top: 45%;
  left: 0;
}

.zip-right {
  position: absolute;
  right: 0;
  top: 40%;
}

.box.bg-lightyellow {
  background-color: rgba(255, 244, 208, 0.9);
  margin-top: 0px;
}

.box.bg-lightgreen {
  // background: rgb(144 238 144 / 58);
  background-color: rgba(142, 226, 188, 0.9);
  position: relative;
  margin-top: 0px;
}

.box.bg-lightblue {
  background-color: rgba(214, 247, 255, 0.9);
  // background: rgb(173 216 230 / 69);
  margin-top: 0px;
}

.box.bg-lightpink {
  background-color: rgba(255, 214, 214, 0.9);
  // background: rgb(255 182 193 / 58);gb 255 214 214
}

.box.bg-lightslategray {
  background-color: rgba(232, 210, 255, 0.9);
  // background: rgb(188 196 235 / 64);rgb 232 210 255
  position: relative;
}

.box {
  width: 270px;
  height: 280px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: -30px;
}

.box img {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.box-title {
  text-align: center;
  font-size: 26px;
  line-height: 1.31;
  font-weight: bold;
}

.box-title span {
  font-size: 14px;
}

.box-container {
  width: 70%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.box-heart {
  position: absolute;
  top: 70%;
  left: 8%;
}

.box-sun {
  position: absolute;
  right: 8%;
  top: 60%;
}

.joinow {
  padding-top: 25px;
}

.ul-expert-advice {
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ul-expert-advice::-webkit-scrollbar {
  width: 5px;
}

/* Track */

/* Handle */
.ul-expert-advice::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #e9e9e9;
}

.ul-expert-advice::-webkit-scrollbar-thumb:window-inactive {
  background: #e9e9e9;
}

.img-box-expert {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px;
  text-align: center;
}

.img-box-expert img {
  width: 100%;
  height: 100%;
}

.our-expert {
  text-align: center;
}

.our-expert h2 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.our-expert h6 {
  font-size: 14px;
  font-weight: normal;
  color: #6e6e6e;
  margin-bottom: 0;
}

.our-expert p {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}

.wave-img {
  width: 100px;
}

.our-expert-full {
  margin-top: 30px;
}

.button-expert {
  text-align: center;
  margin: 50px 0 20px 0;
}

.button-expert a {
  border-radius: 25px !important;
  background-color: $pink-color !important;
  padding: 10px 50px;
  border-color: $pink-color !important;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-stretch: normal;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.button-expert a:hover {
  text-decoration: none;
  color: #fff;
}

.expert-heading {
  margin: 50px 0 40px 0;
}

.plan-left {
  position: absolute;
  top: 140px;
  left: 155px;
  z-index: auto;
}

.rocket-right {
  position: absolute;
  top: 140px;
  right: 106px;
  z-index: auto;
}

.shape2new.shape2newblue {
  position: absolute;
  z-index: -1;
  bottom: 70px;
  right: 27%;
  left: auto;
  text-align: center;
  margin: 0 auto;
  top: auto;
}

.shape2new.shape3newblue {
  position: absolute;
  z-index: -1;
  bottom: auto;
  right: auto;
  left: 14%;
  text-align: center;
  margin: 0 auto;
  top: 45%;
  transform: rotate(117deg);
}

.aboutbg.commonsectionpadding .dots2 {
  position: absolute;
  bottom: auto;
  left: 16%;
  right: auto;
  top: 9%;
}

.aboutbg.commonsectionpadding .dots2new-home {
  width: 7px;
  height: 7px;
  background-color: #f7c7e1;
  position: absolute;
  bottom: auto;
  left: auto;
  right: 17%;
  top: 13%;
  border-radius: 50%;
}

.aboutbg.commonsectionpadding .dots4new-home {
  width: 9px;
  height: 9px;
  background-color: #f7c7e1;
  position: absolute;
  bottom: auto;
  left: 20%;
  right: auto;
  top: 44%;
  border-radius: 50%;
}

.aboutbg.commonsectionpadding .dots3new-home {
  position: absolute;
  bottom: auto;
  left: auto;
  right: 17%;
  top: 39%;
  border-radius: 50%;
}

.footer-dashboard {
  padding: 7px 0;
  bottom: 0;
  position: absolute;
  width: 91%;
  left: 9%;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.footer-dashboard p {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #1e3a48;
  margin-bottom: 0;
}

.our-vision-section {
  background-image: url("../../img/about/vision-bg.png");
  height: 480px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.our-vision-section-text{
  width: 40%;
  text-align: center;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
}
.our-vision-section-text h5{
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}
.our-vision-section-text p{
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}