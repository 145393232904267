@import "./_03_core.scss";
@import "./_05_views.scss";
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

@media only screen and (max-width: 1366px) {
  // ul.baby-months li {
  //   width: 14% !important;
  // }


  .parent-newsletter .commonboldtext {
    font-size: 40px !important;
  }
  .innerheader p {
    width: 40% !important;
  }
  .logindetails h1 {
    font-size: 40px;
    padding: 0 0px;
  }
  .logindetails .regstarshape {
    position: absolute;
    left: -103px;
  }
  .logindetails .getmembershipmsg {
    margin: 20px 0 37px 0px;
  }
  .getmembershipinfo ul {
    margin: 0;
    padding: 0;
  }
  .logindetails .getmembershipinfo ul li {
    list-style: none;
  }
  .logindetails .getmembershipinfo ul li p {
    font-size: 14px;
  }
  .reg-price {
    border-top: 1px solid #e5d7df;
    width: 68%;
    margin: 0px auto;
    font-size: 20px;
    font-weight: 600;
    color: #b17b99;
  }
  .subs-month .custom-control {
    padding-left: 0;
  }
  .detail-msg p {
    font-size: 40px;
  }
  .aboutcontent ul li {
    font-size: 18px;
  }
  .commonstarshape {
    position: absolute;
    left: 20px;
  }
  .commontriangleshape {
    position: absolute;
    right: 20px;
  }
  .aboutcontent h5 {
    font-size: 35px;
    margin: 20px 0;
  }
  //.moonshape {
  //  position: absolute;
  //  right: 0;
  //  top: -76px;
  //}
  .aboutcontent {
    margin-right: 0;
  }
  .mobileapp_born_smart h3 {
    font-size: 30px;
  }
  .contact-list ul li p {
    font-size: 14px;
  }
  .hexagonshape img {
    width: 150px;
  }
}

@media only screen and (max-width: 1600px) {
  .logindetails h1 {
    font-size: 40px;
    padding: 0 0;
  }
  .logindetails .regstarshape {
    position: absolute;
    left: -103px;
  }
  .logindetails .getmembershipmsg {
    margin: 20px 0 37px 0;
  }
  .getmembershipinfo ul {
    margin: 0;
    padding: 0;
  }
  .logindetails .getmembershipinfo ul li {
    list-style: none;
  }
  .logindetails .getmembershipinfo ul li p {
    font-size: 14px;
  }
  .reg-price {
    border-top: 1px solid #e5d7df;
    width: 68%;
    margin: 0px auto;
    font-size: 20px;
    font-weight: 600;
    color: #b17b99;
  }
  .subs-month .custom-control {
    padding-left: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575.98px) {
  .about-us-mobile p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 26px;
  }
  .circle {
    width: 130px;
    height: 135px;
    padding: 9px;
  }
  .circle-bgcolorPink span {
    font-size: 25px;
    font-weight: 700;
  }
  .circle-bgcolorBlue span {
    font-size: 25px;
    font-weight: 700;
  }
  .circle-bgcolorBlue p {
    font-size: 13px;
    margin-bottom: 0;
    padding: 0;
  }
  .maintitle {
    width: 100%;
    margin: 0 auto 20px;
  }
  .brainimg img {
    width: 80px;
  }
  .aboutdetails p {
    font-size: 10px;
    margin-bottom: 0;
  }
  .aboutdetails span {
    font-size: 15px;
  }
  .circle-bgcolorPink p {
    padding: 0;
    font-size: 13px;
    margin-bottom: 0;
  }
  .commonsectionpadding {
    padding: 90px 0 40px;
  }
  .aboutdetails {
    margin: 40px 0 0 0;
  }
  .shapegreen img {
    width: 100%;
  }
  .shapegreen {
    left: 10px;
    top: -13px;
    width: 70px;
  }
  .bornsmartdetails h5 {
    font-size: 14px;
    text-align: center;
  }
  .mychild-heading h4 {
    font-size: 35px;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 35px;
  }
  .baby-month-list .custom-control-label {
    font-size: 14px;
    padding: 0 10px;
    margin-right: 0 !important;
    border-radius: 15px;
  }
  ul.baby-months li {
    width: 23% !important;
    margin-right: 5px;
    margin-bottom: 4px;
  }
  .bornsmartdetails p {
    font-size: 16px;
    text-align: center;
  }
  .exprience-btn {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .shapepink img {
    width: 100%;
  }
  .shapepink {
    width: 80px;
    bottom: -5px;
  }
  .video-div-section div {
    width: 100% !important;
    height: auto !important;
  }
  .homepagebanner .carousel-control-next {
    right: 0;
  }
  .joinsmarttitle {
    font-size: 23px;
    width: 100%;
    margin: 0 auto;
    font-weight: 700;
  }
  .textwidth {
    width: 100% !important;
  }
  .joinsmarttext {
    font-size: 14px;
    margin: 20px auto 50px;
    width: 100%;
  }
  .bornsmartbgdesign1 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign1 .bornsmarttext {
    font-size: 16px
  }
  .bornsmartbgdesign7 .bornsmarttext {
    font-size: 16px
  }
  .bornsmartbgdesign4 .bornsmarttext {
    font-size: 16px
  }
  .bornsmarttext {
    font-size: 12px;
  }
  .bornsmartbgdesign2 {
    width: 100px;
    height: 100px;
    padding: 0 8px;
  }
  .bornsmartbgdesign3 {
    width: 100px;
    height: 100px;
  }
  .bornsmartbgdesign4 {
    width: 156px;
    height: 151px;
    margin-top: -50px !important;
  }
  .bornsmartbgdesign5 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign6 {
    width: 100px;
    height: 100px;
  }
  .bornsmartbgdesign7 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign8 {
    width: 100px;
    height: 100px;
    margin-top: -20px !important;
  }
  .bornsmartbgcommon {
    margin: 0 auto 25px;
  }
  .startmembership .fontsmall {
    font-size: 14px;
  }
  .startmembership .fontbig {
    font-size: 14px;
  }
  .startmembership .fontmedium {
    font-size: 14px;
  }
  .joinow a {
    font-size: 14px;
  }
  .font22 {
    font-size: 12px !important;
    padding: 0;
  }
  .startmembership {
    margin-bottom: 9px;
  }
  .parent-title {
    font-size: 28px;
    width: 100%;
  }
  .parent-details {
    width: 100%;
    font-size: 16px;
  }
  .parentcontent {
    margin: 30px 11px;
  }
  .parentcontent p {
    font-size: 16px;
    letter-spacing: normal;
    margin-bottom: 0;
  }
  .paymentbluebg {
    margin-bottom: 80px;
  }
  .smartboldtext {
    font-size: 30px;
    width: 61%;
  }
  .membershipbtn {
    font-size: 14px !important;
  }
  .freebgshape {
    right: 23px;
  }
  .video-btn2 .commonbtn {
    font-size: 16px;
  }
  .video-btn1 .commonborderbtn {
    font-size: 16px;
  }

  .handimage {
    position: inherit;
    bottom: -92 px;
  }
  .nav {
    display: flex;
    justify-content: space-around;
  }
  .footerinfolinks ul li a {
    font-size: 12px;
  }
  .footerinfolinks ul {
    display: flex;
  }
  .footerinfolinks ul li:nth-child(1) {
    padding-right: 10px;
  }
  .footerinfolinks ul li img {
    margin-right: 3px;
    width: 14px;
  }
  .footerinfolinks {
    margin-top: 20px;
  }
  .navbar-navi {
    margin: 0 10px !important;
  }
  .footerinfolinks ul {
    justify-content: center;
  }
  .copyright {
    text-align: center;
  }
  .sociallinks {
    margin-top: 20px;
  }
  .footer-contact {
    text-align: center;
    margin: 20px 0;
  }
  .commonboldtext {
    font-size: 22px;
  }
  .faq-top-div {
    margin-top: 30px;
  }
  .loginmargin {
    margin: 20px 0;
  }
  .formdiv {
    width: 100%;
    margin: 0 auto;
  }
  .logindetails h1 {
    font-size: 27px;
    padding: 0 0;
  }
  .loginbtn .commonbtn {
    padding: 8px 30px;
    font-size: 17px;
  }
  .registerbtn {
    font-size: 17px;
    padding: 8px 30px;
  }
  .registerbtndiv p {
    font-size: 15px;
    margin: 0 0 10px 0;
  }
  .logincopyright {
    margin-top: 10px;
  }
  .navbar {
    border-bottom: 1px solid #f7f7f7;
  }
  .mobile-login-img-none {
    display: none;
  }
  .registerbtndiv span {
    font-size: 16px;
  }
  .innerheader h1 {
    font-size: 46px;
  }
  .innerheader p {
    width: 94% !important;
    font-size: 16px;
  }
  .commontriangleshape {
    right: 10px;
    width: 60px;
  }
  .commontriangleshape img {
    width: 100%;
  }
  .aboutcontent h5 {
    font-size: 20px;
    text-align: center !important;
  }
  .aboutcontent h6 {
    font-size: 14px;
    margin: 0 auto 20px;
    text-align: center;
  }
  .aboutcontent {
    font-size: 14px;
  }
  .hexagonshape {
    left: 0;
    width: 60px;
  }
  .hexagonshape img {
    width: 100%;
  }
  .aboutcontent p {
    font-size: 14px;
    text-align: left;
  }
  .vision-list li p {
    font-size: 14px;
    text-align: left;
    margin: 0;
    padding: 0 24px 0 27px;
  }
  .moonshape {
    right: 112px;
    top: -6px;
    width: 30px;
  }
  .quotes-left {
    left: 10px;
  }
  .quotes-left img {
    width: 37px;
  }
  .quotes-right {
    right: 10px;
  }
  .quotes-right img {
    width: 37px;
  }
  .detail-msg p {
    font-size: 16px;
  }
  .child-right {
    top: -99 px;
  }
  .parent-newsletter .commonboldtext {
    font-size: 26px !important;
  }
  .sociallinks ul {
    margin-bottom: 14px;
  }
  .child-right {
    top: -104 px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar {
    border-bottom: 1px solid #f7f7f7;
  }
  .logindetails h1 {
    font-size: 22px;
  }
  .formdiv {
    width: 100%;
    margin: 0 auto;
  }
  .loginmargin {
    margin: 50px 0 30px 0;
  }
  .loginbtn .commonbtn {
    padding: 8px 30px;
    font-size: 14px;
    width: 100%;
  }
  .registerbtn {
    font-size: 14px;
    padding: 8px 30px;
  }
  .registerbtndiv {
    margin: 10px 0 0 0;
  }
  .registerbtndiv p {
    font-size: 13px;
  }
  .logincopyright {
    margin-top: 30px;
  }
  .registerbtndiv span {
    font-size: 14px;
  }
  .bornsmartbgdesign1 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign1 .bornsmarttext {
    font-size: 16px
  }
  .bornsmartbgdesign7 .bornsmarttext {
    font-size: 16px
  }
  .bornsmartbgdesign4 .bornsmarttext {
    font-size: 16px
  }
  .bornsmarttext {
    font-size: 16px !important;
  }
  .bornsmartbgdesign2 {
    width: 140px;
    height: 140px;
    padding: 0 8px;
  }
  .bornsmartbgdesign3 {
    width: 140px;
    height: 140px;
  }
  .bornsmartbgdesign4 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign5 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign6 {
    width: 140px;
    height: 140px;
  }
  .bornsmartbgdesign7 {
    width: 156px;
    height: 151px;
  }
  .bornsmartbgdesign8 {
    width: 150px;
    height: 150px;
  }
  .maintitle {
    font-size: 22px;
    width: 100%;
    margin: 0 auto 50px;
  }
  .circle {
    width: 250px;
    height: 250px;
  }
  .circle-bgcolorPink p {
    font-size: 18px;
  }
  .circle-bgcolorBlue p {
    font-size: 18px;
  }
  .aboutdetails span {
    font-size: 20px;
  }
  .aboutdetails p {
    font-size: 14px;
  }
  .shapegreen {
    top: -25 px;
    width: 100px;
  }
  .shapegreen img {
    width: 100%;
  }
  .video-div-section div {
    width: 100% !important;
  }
  .shapepink {
    right: 0;
    bottom: -16 px;
    width: 100px;
    left: auto;
  }
  .shapepink img {
    width: 100%;
  }
  .joinsmarttitle {
    font-size: 35px;
    width: 100%;
  }
  .textwidth {
    width: 100% !important;
  }
  .startmembership {
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .joinow a {
    color: #fff;
    font-size: 18px;
  }
  .startmembership .fontbig {
    font-size: 15px;
  }
  .startmembership .fontsmall {
    font-size: 13px;
  }
  .startmembership .fontmedium {
    font-size: 16px;
  }
  .parent-title {
    font-size: 41px;
    width: 100%;
  }
  .parent-details {
    width: 100%;
    font-size: 18px;
  }
  .parentcontent {
    margin: 0;
  }
  .parentcontent p {
    font-size: 14px;
  }
  .joinsmarttext {
    font-size: 18px;
    width: 100%;
  }

  .colorpink {
    color: #ff1395;
  }
  .smartboldtext {
    font-size: 23px;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 11px;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .membershipbtn {
    font-size: 12px !important;
    padding: 4px;
  }
  .homepagebanner .carousel-control-next {
    right: 0;
  }
  .commonboldtext {
    font-size: 24px;
  }
  .handimage {
    position: inherit;
    bottom: -92px;
  }
  .faq-top-div {
    margin-top: 30px;
  }
  .footerbg {
    padding: 10px 0;
  }
  .navbar-navi {
    margin-right: 16px;
  }

  .sociallinks {
    text-align: left;
  }
  .footer-contact {
    margin-bottom: 20px;
  }
  .copyright {
    text-align: center;
  }
  .carousel-inner {
    overflow: inherit !important;
  }
  .slidercontent {
    width: 230px;
    height: 230px;
    padding: 0 11px;
    position: relative;
  }
  .slidercontent h3 {
    font-size: 15px;
  }
  .slidercontent p {
    font-size: 11px;
    margin-bottom: 0;
  }
  .bannerbtn .commonbtn {
    font-size: 10px;
    width: 62%;
  }
  .bannerbtn .commonborderbtn {
    font-size: 10px;
    width: 62%;
  }
  ul.baby-months li {
    width: 16% !important;
  }
  .baby-month-list .custom-control-label {
    font-size: 14px;
  }
  .commonsectionpadding {
    padding: 50px 0;
    position: relative;
  }
  .homepagebanner .carousel-control-prev {
    left: 0;
  }
  .moonshape img {
    width: 100% !important;
  }
  .innerheader h1 {
    font-size: 50px;
  }
  .innerheader p {
    width: 100% !important;
    font-size: 16px;
  }
  .aboutcontent h5 {
    font-size: 25px;
  }
  .aboutcontent h6 {
    font-size: 16px;
  }
  .aboutcontent {
    font-size: 16px;
  }
  .aboutcontent p {
    font-size: 16px !important;
    text-align: left !important;
  }
  .quotes-right img {
    width: 60px;
  }
  .quotes-left img {
    width: 60px;
  }
  .detail-msg p {
    font-size: 25px;
  }
  .parent-newsletter .commonboldtext {
    font-size: 25px !important;
  }
  .moonshape {
    right: 11px;
    top: -16 px;
  }
  .box-container {
    width: 100%;
  }
  .box.bg-lightblue {
    margin-top: -30px;
  }
  .box {
    margin: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar {
    border-bottom: 1px solid #f7f7f7;
  }
  .logindetails h1 {
    font-size: 22px;
  }
  .formdiv {
    width: 100%;
    margin: 0 auto;
  }
  .loginmargin {
    margin: 50px 0 30px 0;
  }
  .loginbtn .commonbtn {
    padding: 8px 30px;
    font-size: 14px;
    width: 100%;
  }
  .registerbtn {
    font-size: 14px;
    padding: 8px 30px;
  }
  .registerbtndiv {
    margin: 10px 0 0 0;
  }
  .registerbtndiv p {
    font-size: 13px;
  }
  .logincopyright {
    margin-top: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1024.98px) {
  .our-vision-section-text img {
    width: 50%;
  }
  .our-vision-section-text h5 {
    font-size: 24px;
  }
  .our-vision-section-text p {
    font-size: 14px;
  }
  .our-vision-section {
    height: 237px;
  }
  .paymentbluebg {
    height: 630px;
  }
  .paymentpinkbg {
    height: 630px;
  }
  .aboutcontent h6 {
    font-size: 16px;
  }
  .vision-list li p {
    font-size: 16px;
  }
  .innerheader p {
    font-size: 16px;
  }
  .innerheader h1 {
    font-size: 60px;
    margin: 0 0 15px 0;
  }
  .our-vision-section-text h5 {
    font-size: 27px;
  }
  .our-vision-section-text p {
    font-size: 14px;
  }
  .our-vision-section {
    height: 323px;
  }
  .our-vision-section-text img {
    width: 36px;
  }
  .commontriangleshape img {
    width: 90px;
  }
}

@media (min-width: 1024px) and (max-width: 1199.98px) {
  .paymentbluebg {
    height: 630px;
  }
  .paymentpinkbg {
    height: 630px;
  }
  .innerheader p {
    font-size: 16px;
  }
  .aboutcontent h6 {
    font-size: 18px;
  }
  .vision-list li p {
    font-size: 18px;
  }
  .contact-list ul li {
    margin: 0 20px 20px 0;
    padding: 13px;
  }
  .content-covers-img-box {
    margin-left: -70px;
    margin-top: 100px;
  }
  .parent-newsletter .commonboldtext {
    font-size: 36px !important;
  }
  .our-vision-section-text p {
    font-size: 18px;
  }
  .hexagonshape {
    left: 0;
  }
  .commonstarshape img {
    width: 90px;
  }
  .commontriangleshape img {
    width: 90px;
  }
  .our-vision-section-text h5 {
    font-size: 40px;
  }
  .our-vision-section {
    height: 323px;
  }
}

@media (min-width: 1200px) and (max-width: 1299.98px) {
  .paymentbluebg {
    height: 580px;
  }
  .paymentpinkbg {
    height: 580px;
  }
  .pricinglist li {
    font-size: 14px;
  }
  .contact-list ul li {
    margin: 0 20px 20px 0;
    padding: 13px;
  }
  .parent-newsletter .commonboldtext {
    font-size: 48px !important;
  }
  .our-vision-section-text p {
    font-size: 21px;
  }
  .our-vision-section-text h5 {
    font-size: 40px;
  }
  .hexagonshape {
    left: 0;
  }
  .our-vision-section {
    height: 387px;
  }
}

@media (min-width: 1300px) and (max-width: 1399.98px) {
  .our-vision-section {
    height: 387px;
  }
  .knowtitle {
    font-size: 30px;
  }
  .expert-sectionpadding {
    padding: 70px 0 70px;
  }
  .h-slick-slider button.slick-arrow.slick-prev {
    top: 102%;
  }
  .h-slick-slider button.slick-arrow.slick-next {
    top: 102%;
  }
  .parent-newsletter .commonboldtext {
    font-size: 44px;
    letter-spacing: -1px;
  }
  .pricinglist li {
    font-size: 14px;
  }
  .maintitle {
    font-size: 20px;
    line-height: 32px;
  }
  .slidercontent h3 {
    font-size: 35px;
    line-height: initial;
  }
  .slidercontent {
    width: 400px;
    height: 400px;
  }
  .circle-bgcolorBlue p {
    font-size: 18px;
  }
  .circle-bgcolorPink p {
    font-size: 18px;
  }
  .circle {
    width: 250px;
    height: 250px;
  }
  .aboutdetails span {
    font-size: 20px;
  }
  .aboutdetails p {
    font-size: 14px;
  }
  ul.baby-months li {
    width: 20%;
  }
  ul.baby-months {
    margin: 40px 0 0 0;
  }
  .joinsmarttitle {
    font-size: 35px;
  }
  .bornsmartbgdesign1 {
    width: 220px;
    height: 220px;
  }
  .bornsmartbgdesign2 {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  .bornsmartbgdesign4 {
    width: 240px;
    height: 240px;
    margin-top: -38px;
  }
  .bornsmartbgdesign5 {
    width: 230px;
    height: 230px;
    margin-top: 30px;
  }
  .bornsmarttext {
    font-size: 20px;
  }
  .startmembership .fontbig {
    font-size: 20px;
  }
  .joinow a {
    font-size: 20px;
  }
  .font22 {
    font-size: 20px !important;
  }
  .bornsmartbgdesign7 {
    width: 220px;
    height: 220px;
    margin-top: -30px;
  }
  .startmembership .fontmedium {
    font-size: 20px;
  }
  .bornsmartbgdesign6 {
    width: 200px;
    height: 200px;
  }
  .bornsmartbgdesign8 {
    width: 170px;
    height: 170px;
    margin-top: -30px;
  }
  .bornsmartbgdesign3 {
    width: 200px;
    height: 200px;
  }
  .paymentpinkbg {
    height: 580px;
  }
  .paymentbluebg {
    height: 580px;
  }
  .membershipbtn {
    position: absolute;
  }
  .aboutcontent h6 {
    font-size: 18px;
  }
  .vision-list li p {
    font-size: 18px;
  }
  .faq-q-head h5 {
    font-size: 34px;
  }
  .h-cardTitle h5 {
    font-size: 16px;
  }
  .faq_cardBody p {
    font-size: 14px;
  }
  .faq-img-box-active .faq-name h3 {
    font-size: 16px;
  }
  .faq-name h3 {
    font-size: 16px;
  }
  .commonstarshape img {
    width: 50%;
  }
  .commontriangleshape img {
    width: 50%;
  }
  .testi-desc h4 {
    font-size: 16px;
  }
  .content-covers-img-box iframe {
    height: 250px;
    margin: 75px 0;
  }
  .testi-data {
    padding: 0 30px 0 0;
  }
  .our-vision-section-text p {
    font-size: 21px;
  }
  .our-vision-section-text h5 {
    font-size: 40px;
  }
}

@media (min-width: 1400px) and (max-width: 1439.98px) {
  .our-vision-section-text p {
    font-size: 24px;
  }
}

@media (min-width: 1440px) and (max-width: 1499.98px) {
  .content-covers-img-box {
    margin-left: -76px;
    margin-top: 55px !important;
    margin-right: 10% !important;
  }
  .content-covers-full {
    padding: 40px;
  }
  .commonstarshape img {
    width: 60%;
  }
  .commontriangleshape img {
    width: 60%;
  }
  .free-membership-box-full .wave-left {
    left: 100px;
    top: 50%;
    right: auto;
  }
  .free-membership-box-full .wave-right {
    top: 50%;
    right: 0;
  }
  .dots3 {
    position: absolute;
    bottom: 15%;
    right: -2%;
  }
  .maintitle {
    font-size: 20px;
    line-height: 32px;
  }
  .slidercontent h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .slidercontent {
    width: 400px;
    height: 400px;
  }
  .circle-bgcolorBlue p {
    font-size: 18px;
  }
  .circle-bgcolorPink p {
    font-size: 18px;
  }
  .circle {
    width: 250px;
    height: 250px;
  }
  .aboutdetails span {
    font-size: 20px;
  }
  .aboutdetails p {
    font-size: 14px;
  }
  ul.baby-months li {
    width: 20%;
  }
  ul.baby-months {
    margin: 40px 0 0 0;
  }
  .joinsmarttitle {
    font-size: 35px;
  }
  .bornsmartbgdesign1 {
    width: 220px;
    height: 220px;
  }
  .bornsmartbgdesign2 {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  .bornsmartbgdesign4 {
    width: 240px;
    height: 240px;
    margin-top: -38px;
  }
  .bornsmartbgdesign5 {
    width: 230px;
    height: 230px;
    margin-top: 30px;
  }
  .bornsmarttext {
    font-size: 20px;
  }
  .startmembership .fontbig {
    font-size: 20px;
  }
  .joinow a {
    font-size: 20px;
  }
  .font22 {
    font-size: 20px !important;
  }
  .bornsmartbgdesign7 {
    width: 220px;
    height: 220px;
    margin-top: -30px;
  }
  .startmembership .fontmedium {
    font-size: 20px;
  }
  .bornsmartbgdesign6 {
    width: 200px;
    height: 200px;
  }
  .bornsmartbgdesign8 {
    width: 170px;
    height: 170px;
    margin-top: -30px;
  }
  .bornsmartbgdesign3 {
    width: 200px;
    height: 200px;
  }
  .paymentpinkbg {
    height: 640px;
  }
  .paymentbluebg {
    height: 640px;
  }
  .membershipbtn {
    position: absolute;
  }
  .our-vision-section-text p {
    font-size: 30px;
  }
  .our-vision-section {
    height: 479px;
  }
}

@media (min-width: 1500px) and (max-width: 1599.98px) {
  .our-vision-section-text p {
    font-size: 30px;
  }
  .our-vision-section-text h5 {
    font-size: 50px;
  }
  .our-vision-section {
    height: 479px;
  }
  .maintitle {
    font-size: 20px;
    line-height: 32px;
  }
  .slidercontent h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .slidercontent {
    width: 400px;
    height: 400px;
  }
  .circle-bgcolorBlue p {
    font-size: 18px;
  }
  .circle-bgcolorPink p {
    font-size: 18px;
  }
  .circle {
    width: 250px;
    height: 250px;
  }
  .aboutdetails span {
    font-size: 20px;
  }
  .aboutdetails p {
    font-size: 14px;
  }
  ul.baby-months li {
    width: 20%;
  }
  ul.baby-months {
    margin: 40px 0 0 0;
  }
  .joinsmarttitle {
    font-size: 35px;
  }
  .bornsmartbgdesign1 {
    width: 220px;
    height: 220px;
  }
  .bornsmartbgdesign2 {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  .bornsmartbgdesign4 {
    width: 240px;
    height: 240px;
    margin-top: -38px;
  }
  .bornsmartbgdesign5 {
    width: 230px;
    height: 230px;
    margin-top: 30px;
  }
  .bornsmarttext {
    font-size: 20px;
  }
  // .startmembership .fontbig {
  //   font-size: 20px;
  // }
  .joinow a {
    font-size: 20px;
  }
  .font22 {
    font-size: 20px !important;
  }
  .bornsmartbgdesign7 {
    width: 220px;
    height: 220px;
    margin-top: -30px;
  }
  .startmembership .fontmedium {
    font-size: 20px;
  }
  .bornsmartbgdesign6 {
    width: 200px;
    height: 200px;
  }
  .bornsmartbgdesign8 {
    width: 170px;
    height: 170px;
    margin-top: -30px;
  }
  .bornsmartbgdesign3 {
    width: 200px;
    height: 200px;
  }
  .paymentpinkbg {
    height: 600px;
  }
  .paymentbluebg {
    height: 600px;
  }
  .membershipbtn {
    position: absolute;
  }
  .innerheader h1 {
    font-size: 60px;
  }
  .faq-q-head h5 {
    font-size: 42px;
  }
  .commonstarshape img {
    width: 75%;
  }
  .commontriangleshape img {
    width: 75%;
  }
  .centerImgLeft img {
    width: 75%;
  }
  .centerImgRight img {
    width: 75%;
  }
  .h-cardTitle h5 {
    font-size: 19px;
  }
  .faq_cardBody p {
    font-size: 15px;
  }
  .privacy-content .contDetails b {
    font-size: 30px;
  }
  .privacy-content .contDetails p {
    font-size: 15px;
  }

}

@media (min-width: 1600px) and (max-width: 1699.98px) {
  .content-covers-img-box {
    margin-left: -70px;
    margin-top: 22px;
    margin-right: 76px;
  }
  .free-membership-box-full .wave-left {
    left: 100px;
    top: 50%;
    right: auto;
  }
  .free-membership-box-full .wave-right {
    left: auto;
    top: 50%;
    right: 0;
  }
  .star {
    top: 87%;
  }
  .red-star {
    bottom: 9%;
  }
  .dots3 {
    right: 0;
  }
  .our-vision-section {
    height: 479px;
  }
  .our-vision-section-text p {
    font-size: 30px;
  }
}